<template>
    <span>
      {{ title }}
      <a-tooltip v-if="tooltip" :placement="tooltipPlacement">
        <template #title>
          <div class="pre-line">
            {{ tooltip }}
          </div>  
        </template>
        <InfoCircleOutlined class="tooltip-icon"/>
      </a-tooltip>
    </span>
  </template>
  
  <script>
  import { InfoCircleOutlined } from '@ant-design/icons-vue'
  export default {
    name: 'TooltipLabel',
    components: {
        InfoCircleOutlined
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      tooltip: {
        type: String,
        default: null
      },
      tooltipPlacement: {
        type: String,
        default: 'top'
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .tooltip-icon {
      color: rgba(0,0,0,.45);
    }
  </style>