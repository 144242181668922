<template>
    <a-modal
      v-if="openModal"
      style="height: 50em;"
      v-model:open="openModal"
      :title="'Benutzer bearbeiten'"
      :closable="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :loading="loading"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleSubmit"
      centered>
        <a-form
          :ref="formRef"
          :model="form"
          :rules="rules"
          :loading="loading"
          layout="vertical"
          @finish="handleSubmit">
          <!-- WP Implementation -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="username" name="username">
                <template #label>
                  <TooltipLabel :title="'Name des Benutzers'" :tooltip="'Name des eigentlichen Benutzer, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
                </template>
                <a-input v-model:value="form.username" :placeholder="'Name des Benutzerkontos'" />
              </a-form-item>
            </a-col>
            <!-- Email -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="email" name="email">
                <template #label>
                  <tooltip-label :title="'E-Mail'" :tooltip="'Bitte geben Sie die E-Mail des Nutzers an.\nEs muss eine gültige Email sein.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_@.'"/>
                </template>
                <a-input
                  v-model:value="form.email"
                  :placeholder="'E-Mail'" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="firstname" name="firstname">
                <template #label>
                  <tooltip-label :title="'Vorname'" :tooltip="'Bitte geben Sie den Vornamen des Nutzers an.'"/>
                </template>
                <a-input
                  v-model:value="form.firstname"
                  :placeholder="'Vorname'" />
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item ref="lastname" name="lastname">
                <template #label>
                  <tooltip-label :title="'Nachname'" :tooltip="'Bitte geben Sie den Nachnamen des Nutzers an.'"/>
                </template>
                <a-input
                  v-model:value="form.lastname"
                  :placeholder="'Nachname'" />
              </a-form-item>
            </a-col>
          </a-row>
      </a-form>
    </a-modal>
</template>

<script>

import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import { notification } from 'ant-design-vue'

export default {

    name: 'EditUsers',
    components: {
      TooltipLabel,
      notification
    },
    props: [
      "userResource",
      "editUserModal"
    ],
    data() {
      return {
        emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
        specialCharsMail: /[-_@.]/,
        openModal: false,
        specialChars: /[`´!@€µ°²³#$%^§&*()+=[\]{};':"\\|,<>/?ß~]/,
        loading: false,
      }
    },
    watch: {
      editUserModal: function(newVal, oldVal) {
        this.openModal = newVal
      },
      userResource: {
        handler: function(newVal, oldVal) {
          this.form.username = this.userResource.username
          this.form.email = this.userResource.email
          this.form.firstname = this.userResource.firstname
          this.form.lastname = this.userResource.lastname
        },
        deep: true
      },
    },
    created() {
      this.initForm()
    },
    methods: {
      initForm () {
        this.formRef = ref()
        this.form = reactive({
          username: undefined,
          email: undefined,
          firstname: undefined,
          lastname: undefined
        })
        this.rules = reactive({
          username: [
            { required: true, message: 'Bitte geben Sie einen Benutzernamen an.' },
            { validator: this.validateAccountName }
          ],
          email: [
            { required: true, message: '' },
            { validator: this.validateEmail }
          ],
          firstname: [{ required: true, message: 'Bitte geben Sie den Vornamen des Nutzers an.' }],
          lastname: [{ required: true, message: 'Bitte geben Sie den Nachnamen des Nutzers an.' }],
        })
      },
      validateAccountName (rule, value) {
        var errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!'
        var validated = true;
        if (this.specialChars.test(value)) {
          validated = false;
          errorMessage += "\nSonderzeichen sind nicht erlaubt!"
        }
        if (value==undefined || value.length < 4 ) {
          validated = false;
          errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!"
        }
        if (validated){
          return Promise.resolve()
        } else {
          return Promise.reject(errorMessage)
        }
      },
      validateEmail (rule, value) {
        const errorMessage = 'Bitte geben Sie eine gültige E-Mail an. \nBeispiel: Beispiel@Email1.com\nNur Zahlen, Buchstaben und -_@. sind erlaubt!'
        if (this.emailRegex.test(value) && this.specialCharsMail.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
      },
      handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(async () => {
        const values = toRaw(this.form)
        if (values.username === this.userResource.username && values.email === this.userResource.email && values.firstname === this.userResource.firstname && values.lastname === this.userResource.lastname) {
          this.$emit('close-modal')
          this.loading = false
          return
        }
        this.loading = true

        // WP Implementation
        var params = new FormData()
        params.append('useruuid', this.userResource.userUuid)
        params.append('username', values.username)
        params.append('email', values.email)
        params.append('firstname', values.firstname)
        params.append('lastname', values.lastname)

        await api.updateUser(params).then(response => {
          this.closeModal()
          this.$notification.success({
            message: 'Erfolg',
            description: 'Der Benutzer wurde erfolgreich aktualisiert.',
            duration: 6
          })
        }).catch(error => {
          console.log(error)
          this.closeModal()
          this.$notification.error({
            message: 'Fehler beim Aktualisieren eines Benutzers.',
            description:
                'Beim Aktualisieren eines Benutzers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
            duration: 0
          })
        })
        this.closeModal()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    closeModal () {
      this.form.username = this.userResource.username
      this.form.email = this.userResource.email
      this.form.firstname = this.userResource.firstname
      this.form.lastname = this.userResource.lastname
      this.openModal = false
      this.loading = false
      this.$emit('reload-user', this.userResource.account, this.userResource.domainid)
      this.$emit('close-modal')
    }
    }
}
</script>

<style>

</style>
