<template>
  <div class="centering"> 
    <div>
      <div class="selectButton">
        <a-select ref="select" v-model:value="year" style="width: 30%" @change="getInvoiceMonths(year)">   
        <a-select-option :value="new Date().getFullYear()">{{ new Date().getFullYear() }}</a-select-option>
        <a-select-option :value="new Date().getFullYear()-1">{{new Date().getFullYear()-1}}</a-select-option>
        </a-select>
        <span v-if="invoiceDataTable.length === 0" >Keine Daten für diesen Zeitraum!</span>
      </div>
      <a-table v-if="invoiceDataTable.length !== 0" :columns="invoiceCols" :dataSource="invoiceDataTable" :loading="invoiceLoading" bordered :pagination="false">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'download'">
            <span>
              <a @click = "invoiceData(monthsEnglish.get(record.month))">Rechnung {{ record.month }} {{ this.year }}</a>
            </span>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import api from '@/apis/apis'

export default {
  name: 'Invoices',
  data () {
    return {
      year: new Date().getFullYear(),
      invoiceLoading: false,
      invoiceDataTable: [],
      currentMonth: new Date().getMonth(),
      monthIntMap: new Map([["Januar", 0], ["Februar", 1], ["März", 2], ["April", 3], ["Mai", 4], ["Juni", 5], ["Juli", 6], ["August", 7], ["September", 8], ["Oktober", 9],["November", 10], ["Dezember", 11],]),
      monthsEnglish: new Map([["Januar","January"], ["Februar","February"], ["März","March"], ["April","April"], ["Mai","May"], ["Juni","June"], ["Juli","July"], ["August","August"], ["September","September"], ["Oktober","October"],["November","November"], ["Dezember","December"],]),
      invoiceCols: [
        {
          title: 'Monat',
          key: 'month',
          dataIndex:'month',
        },
        {
          title: 'Herunterladen',
          key: 'download',
        },
      ],
    }
  },
  async mounted () {
    await this.getInvoiceMonths(this.year)
  },
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    async getInvoiceMonths (year) {
      var table = [
          {
            month: 'Dezember',
          },
          {
            month:'November',
          },
          {
            month:'Oktober',
          },
          {
            month:'September',
          },
          {
            month:'August',
          },
          {
            month:'Juli',
          },
          {
            month:'Juni',
          },
          {
            month:'Mai',
          },
          {
            month:'April',
          },
          {
            month:'März',
          },
          {
            month:'Februar',
          },
          {
            month:'Januar',
          },
        ]
      this.invoiceLoading = true
      var params = new FormData()
      params.append('accountuuid', this.getCookie('account'))
      params.append('year', year)
      await api.checkActiveInvoices(params).then(response => {
        if (response.data !== null) {
          for (const [i, month] of table.entries()){
          if (!response.data.includes(this.monthsEnglish.get(month.month))) {
            delete table[i]
          }
        }
        } else table = []
        this.invoiceDataTable = table
        this.invoiceLoading = false
      }).catch(error => {
        console.log(error)
        this.invoiceLoading = false
      })
    },
    invoiceData(month){
      let params = new FormData()
        params.append('account_uuid', this.getCookie('account'))
        params.append('month', month)
        params.append('year', this.year)

        api.getInvoice(params).then(response => {
        let filename = response.headers['content-disposition'].split("filename=")[1]
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>

.centering {
  width: 100%; 
  flex-direction: column; 
  display: flex; 
  justify-content: center; 
  align-items: center;
}
.selectButton {
  display: flex; 
  justify-content: left; 
  flex-direction: row;
  width: 300px;
  margin-bottom: 1%;
  margin-top: 40px;
}
</style>