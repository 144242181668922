<template>
    <a-modal
      v-if="openModal"
      style="height: 50em;"
      v-model:open="openModal"
      :title="'Benutzerkonto bearbeiten'"
      :closable="false"
      :maskClosable="false"
      :destroyOnClose="true"
      :confirmLoading="loading"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleSubmit"
      centered>
        <a-form
          :ref="formRef"
          :model="form"
          :rules="rules"
          :loading="loading"
          layout="vertical"
          @finish="handleSubmit">
          <!-- WP Implementation -->
          <a-form-item ref="accountName" name="accountName">
            <template #label>
              <TooltipLabel :title="'Name des Benutzerkontos'" :tooltip="'Name des eigentlichen Benutzerkontos, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
            </template>
            <a-input v-model:value="form.accountName" :placeholder="'Name des Benutzerkontos'" />
          </a-form-item>
          <a-form-item ref="companyname" name="companyname">
            <template #label>
              <tooltip-label :title="'Firmenname'" :tooltip="'Firmenname, welcher in den Rechnungen angezeigt wird. Alle Sonderzeichen sind erlaubt, da dies nur ein reiner Anzeige-Name und sonst nicht für das System relevant ist.'"/>
            </template>
            <a-input
              v-model:value="form.companyname"
              placeholder="Name der Firma" />
          </a-form-item>
          <!-- Customer NR -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="customerID" name="customerID">
                <template #label>
                  <tooltip-label :title="'Kundennummer'" :tooltip="'Bitte geben Sie eine Kundennummer an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_'"/>
                </template>
                <a-input
                  v-model:value="form.customerID"
                  :placeholder="'Kundennummer'" />
              </a-form-item>
            </a-col>
            <!-- Contract NR -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="contractNumber" name="contractNumber">
                <template #label>
                  <tooltip-label :title="'Vertragsnummer'" :tooltip="'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'"/>
                </template>
                <a-input
                  v-model:value="form.contractNumber"
                  :placeholder="'Vertragsnummer'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- Street + Nr -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="17">
              <a-form-item ref="street" name="street">
                <template #label>
                  <tooltip-label :title="'Straße + Hausnummer'" :tooltip="'Bitte geben Sie eine gültige Adresse an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                v-model:value="form.street"
                  :placeholder="'Straße + Hausnummer'"/>
              </a-form-item>
            </a-col>
            <!-- Postal Code -->
            <a-col :md="24" :lg="7">
              <a-form-item ref="postalcode" name="postalcode">
                <template #label>
                  <tooltip-label :title="'PLZ'" :tooltip="'Bitte geben Sie eine Postleitzahl an.\nPostleitzahlen haben maximal 8 Ziffern.'"/>
                </template>
                <a-input
                v-model:value="form.postalcode"
                  :placeholder="'PLZ'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- City -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="city" name="city">
                <template #label>
                  <tooltip-label :title="'Stadt'" :tooltip="'Bitte geben Sie einen Stadtnamen an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                v-model:value="form.city"
                  :placeholder="'Stadt'"/>
              </a-form-item>
            </a-col>
            <!-- Country -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="country" name="country">
                <template #label>
                  <tooltip-label :title="'Land'" :tooltip="'Bitte geben Sie ein gültiges Land an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                  v-model:value="form.country"
                  :placeholder="'Land'"/>
              </a-form-item>
            </a-col>
          </a-row>
      </a-form>
    </a-modal>
</template>

<script>

import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import { notification } from 'ant-design-vue'

export default {

    name: 'EditUsers',
    components: {
      TooltipLabel,
      notification
    },
    props: {
      editAccountModal: {
        type: Boolean,
        required: true
      },
      accountResource: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        openModal: false,
        emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
        specialCharsMail: /[-_@.]/,
        specialChars: /[`´!@€µ°²³#$%^§&*()+=[\]{};':"\\|,<>/?ß~]/,
        customerIdRegex: /^[0-9]*$/, //only digits
        contractNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/, //only letters, digits, -_.
        cityNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9- ]*$/,
        loading: false,
        tempMitData: []
      }
    },
    watch: {
      editAccountModal: function(newVal, oldVal) {
        if (newVal === true) {
          this.form.accountName = this.accountResource.accountname
          this.listMitAccountData()
        }
        this.openModal = newVal
      },
    },
    created() {
      this.initForm()
    },
    mounted () {
      this.listMitAccountData()
    },
    methods: {
      initForm () {
        this.formRef = ref()
        this.form = reactive({
          accountName: undefined,
          city: undefined,
          postalcode: undefined,
          street: undefined,
          country: undefined,
          customerID: undefined,
          companyname: undefined,
          contractNumber: undefined
        })
        this.rules = reactive({
          accountName: [
            { required: true, message: '' },
            { validator: this.validateAccountName }
          ],
          companyname: [{ required: true, message: 'Bitte geben Sie den Namen der Firma an.' }],
          contractNumber: [
            { required: false, message: 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.' },
            { validator: this.validateContractNumber }
          ],
          city: [
            { required: true, message: 'Bitte geben Sie eine Stadt an.' },
            { validator: this.validateCity }
          ],
          postalcode: [
            { required: true, message: 'Bitte geben Sie eine Postleitzahl an.'},
            { validator: this.validatePlz }
          ],
          street: [
            { required: true, message: 'Bitte geben Sie eine Adresse an.' },
            { validator: this.validateStreet }
          ],
          country: [
            { required: true, message: 'Bitte geben Sie ein Land an.' },
            { validator: this.validateCountry }
          ],
          customerID: [
            { required: true, message: 'Bitte geben Sie eine Kundennummer an.' },
            { validator: this.validateCustomerID }
          ],
        })
      },
      closeModal () {
        /* this.form.companyname = this.tempMitData.CustomerName
        this.form.customerID = this.tempMitData.CustomerNumber
        this.form.country = this.tempMitData.Country
        this.form.street = this.tempMitData.Address
        this.form.postalcode = this.tempMitData.Plz
        this.form.city = this.tempMitData.City */
        this.$emit('close-modal')
      },
      listMitAccountData () {
        // call function to retrieve user data here
        let getAccountDataParams = new FormData()
        getAccountDataParams.append('accountuuid', this.accountResource.accountuuid)
        api.getMitAccountData(getAccountDataParams).then(response => {
          this.tempMitData = response.data
          this.form.companyname = response.data.CustomerName
          this.form.customerID = response.data.CustomerNumber
          this.form.country = response.data.Country
          this.form.street = response.data.Address
          this.form.postalcode = response.data.Plz
          this.form.city = response.data.City
          this.form.contractNumber = response.data.ContractNumber
        }).catch(error => {
          console.log(error)
        })
      },
      handleSubmit (e) {
        e.preventDefault()
        if (this.loading) return
        this.formRef.value.validate().then(async () => {
          const values = toRaw(this.form)
          /* if (values.username === this.userResource.username && values.email === this.userResource.email && values.firstname === this.userResource.firstname && values.lastname === this.userResource.lastname) {
            this.$emit('close-modal')
            this.loading = false
            return
          } */
          this.loading = true

          // WP Implementation
          var params = new FormData()
          params.append('accountuuid', this.accountResource.accountuuid)
          params.append('domainuuid', this.accountResource.domainuuid)
          params.append('newName', values.accountName)
          params.append('customerName', values.companyname)
          params.append('customerNumber', values.customerID)
          params.append('country', values.country)
          params.append('city', values.city)
          params.append('streetName', values.street)
          params.append('plz', values.postalcode)
          if (values.contractNumber == undefined) {
            params.append('contractNumber', "")
          }else{
            params.append('contractNumber', values.contractNumber)
          }

          await api.updateAccount(params).then(response => {
            this.$emit('reload-accounts')
            this.openModal = false
            this.loading = false
            this.$emit('close-modal')
            this.$notification.success({
              message: 'Erfolg',
              description: 'Das Konto wurde erfolgreich aktualisiert.',
              duration: 6
            })
          }).catch(error => {
            console.log(error)
            this.loading = false
            this.openModal = false
            this.$emit('close-modal')
            this.$notification.error({
              message: 'Fehler beim Aktualisieren des Kontos.',
              description:
                  'Bei der Aktualisierung des Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
              duration: 0
            })
          })
        this.$emit('close-modal')
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
      },
      validateAccountName (rule, value) {
        var errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!'
        var validated = true;
        if (this.specialChars.test(value)) {
          validated = false;
          errorMessage += "\nSonderzeichen sind nicht erlaubt!"
        }
        if (value==undefined || value.length < 4 ) {
          validated = false;
          errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!"
        }
        if (validated){
          return Promise.resolve()
        } else {
          return Promise.reject(errorMessage)
        }
      },
      validateEmail (rule, value) {
        const errorMessage = 'Bitte geben Sie eine gültige E-Mail an. \nBeispiel: Beispiel@Email1.com\nNur Zahlen, Buchstaben und -_@. sind erlaubt!'
        if (this.emailRegex.test(value) && this.specialCharsMail.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
      },
      validateCustomerID(rule, value) {
        const errorMessage = 'Bitte geben Sie eine gültige Kundennummer an.\nNur Zahlen sind erlaubt!'
        if (value!=undefined && this.customerIdRegex.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
      },
      validateContractNumber(rule, value) {
        const errorMessage = 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'
        if (this.contractNumberRegex.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
      },
      validateCity(rule, value) {
        const errorMessage = 'Bitte geben Sie einen gültigen Stadtnamen an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
        if (value!=undefined && this.cityNameRegex.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
      },
      validateStreet(rule, value) {
        const errorMessage = 'Bitte geben Sie eine gültige Adresse an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
        if (value!=undefined && this.cityNameRegex.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
      },
      validateCountry(rule, value) {
        const errorMessage = 'Bitte geben Sie ein gültiges Land an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
        if (value!=undefined && this.cityNameRegex.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
      },
      validatePlz (rule, value) {
          const errorMessage = 'Die maximale Länge der Postleitzahl beträgt 8!'
          if (value!=undefined && value.length <= 8) {
            return Promise.resolve()
          }
          return Promise.reject(errorMessage)
        },
    }
}
</script>

<style>

</style>
