<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#D02C2A',
        font: 'Gill Sans MT'
      },
    }"
  >
    <div id="nav">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<style lang="scss">
// @import "~@/assets/scss/vendors/bootstrap-vue/index";
#nav {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a:hover {
  font-weight: bold;
  color: #D02C2A;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
