<template>
  <div v-if="!options[0].includes('container')">
    <div v-if="(serverType === 'iaas' || serverType === 'cloudServer') && options[1].label !== 'Cyber Cloud Endpoint'">
      <div v-if="options[1].isCheckbox ? (selectedServer.type !== 'iaas' ? true : (options[1].label === 'Next Generation Firewall' ? false : true)) : false" class="sideBarElementLine">
        <span>
          <span v-html="options[1].label" :class="{ disabled: isDisabled }">
          </span>&nbsp;
          <a-tooltip id="hint" v-if="options[1].popover.content !== null">
            <template #title>{{ options[1].popover.content }}</template>
            <ExclamationCircleOutlined />
          </a-tooltip>
        </span>
        <div>
          <a-switch
            :id="options[1].label"
            v-model:checked="model"
            :name="'check-' + model"
            :unchecked-value="0"
            :checked="1"
            :disabled="isDisabled"
          >
          </a-switch>
        </div>
      </div>
      <!-- Input Number -->
      <div
        style="display: flex; justify-content: space-between; align-items: center; padding: 2px;"
        :label-for="options[1].label"
        class="align-items-center"
        v-else-if="options[1].isOS === false && options[1].isResource === false"
      >
        <span style="text-align: left; width: 212px;">
          <span v-html="options[1].label" :class="{ disabled: isDisabled }">
          </span>&nbsp;
          <a-tooltip id="hint" v-if="options[1].popover.content !== null">
            <template #title>{{ options[1].popover.content }}</template>
            <ExclamationCircleOutlined />
          </a-tooltip>
        </span>
        <a-input
          type="number"
          style="width: 60px;"
          v-model:value="model"
          :id="options[1].label"
          :min="0"
          :max="200000"
          :disabled="isDisabled"
        ></a-input>
      </div>
      <div
        :label-for="options[1].label"
        id="isResourceInputContainer"
        class="align-items-center sideBarElementLine"
        v-else-if="isResource ? (selectedServer.type !== 'iaas' ? true : (options[1].label === 'Öffentliche IP' || options[1].label === 'Next Generation Firewall' ? false : true)) : false"
      >
        <span style="text-align: left; width: 70%;">
          <span v-html="options[1].label" :class="{ disabled: isDisabled }" style="word-wrap: break-word; width: 100%;">
          </span>&nbsp;
          <a-tooltip id="hint" v-if="options[1].popover.content !== null">
            <template #title>{{ options[1].popover.content }}</template>
            <ExclamationCircleOutlined />
          </a-tooltip>
        </span>
        <a-input
          type="number"
          :class="['inputNumField', { inputFieldRequired: (setRequireField(options[0]) && submitButtonClicked) ? true : false }]"
          v-model:value="model"
          :id="options[1].label"
          :min="0"
          :max="checkMaxLength(options[0])"
          :disabled="isDisabled"
        ></a-input>
      </div>
    </div>
    <div v-if="serverType === 'wpCloud'">
      <div v-if="options[1].label === 'CPU Kerne'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="options[1].label" :class="{ disabled: isDisabled }"></span>&nbsp;
        </span>
        2
      </div>
      <div v-if="options[1].label === 'RAM in GB'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="options[1].label" :class="{ disabled: isDisabled }"></span>&nbsp;
        </span>
        4
      </div>
      <div v-if="options[1].label === 'SSD Storage in GB'" style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="options[1].label" :class="{ disabled: isDisabled }"></span>&nbsp;
        </span>
        100
      </div> 
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

export default {
  name: "ConfigNumberField",
  components: {
    ExclamationCircleOutlined
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    isResource: {
      type: Boolean,
      required: false
    },
    requireCpu: {
      type: Boolean,
      required: false
    },
    requireRam: {
      type: Boolean,
      required: false
    },
    requireStorage: {
      type: Boolean,
      required: false
    },
    submitButtonClicked: {
      type: Boolean,
      required: false
    },
    serverType: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters(["selectedServer"]),
    isDisabled() {
     /*  if ("disabledCondition" in this.options) {
        return this.options.disabledCondition();
      } */
      return false;
    },
    model: {
      get() {
        if (this.options[1].isCheckbox) {
          var modelValue = this.selectedServer.configuration[this.options[0]]
          if (modelValue === 1 ){
            modelValue = true
            return modelValue
          }
        }
        else {
          var modelValue = parseInt(this.selectedServer.configuration[this.options[0]])
        }
        return parseInt(modelValue)
      },
      set(value) {
        if (value >= 0) {
          if (value > 999) {
            if (this.options[0] === "HDD" || this.options[0] === "SSD" || this.options[0] === "MEMORY"){
              if ( value > 9999) {
                value = 9999
                this.updateConfig(this.options[0], value);
              }
            } else {
              value = 999
              this.updateConfig(this.options[0], value);
            }
          }
          if (value === true) {
           value = 1
            this.updateConfig(this.options[0], value);
          } else {
            this.updateConfig(this.options[0], value);
          }
        }
        if (this.options[0] === "HDD" || this.options[0] === "SSD" || this.options[0] === "MEMORY" || this.options[0] === 'vCPU'){
          if (value == ""){
            this.updateConfig(this.options[0], 0);
          }
        }

        // only allow one GPU License to be selected
        if (this.options[0].includes('GPU-')) {
          this.updateGpusInConfig(this.options[0], value);
        }
        if (this.options[0] === 'MSSQL_Standard_(Core_License)' && this.selectedServer.configuration['MSSQL_Standard_(User_License)'] > 0) {
          this.updateConfig(this.options[0], value);
          this.updateConfig('MSSQL_Standard_(User_License)', 0);
        } else if (this.options[0] === 'MSSQL_Standard_(User_License)' && this.selectedServer.configuration['MSSQL_Standard_(Core_License)'] > 0) {
          this.updateConfig(this.options[0], value);
          this.updateConfig('MSSQL_Standard_(Core_License)', 0);
        }
        if (this.options[0] === 'libre' && this.selectedServer.configuration['MS_Office_2021_1_User'] > 0) {
          this.updateConfig(this.options[0], value);
          this.updateConfig('MS_Office_2021_1_User', 0);
        } else if (this.options[0] === 'MS_Office_2021_1_User' && this.selectedServer.configuration['libre'] > 0) {
          this.updateConfig(this.options[0], value);
          this.updateConfig('libre', 0);
        }
        if (this.options[0] === 'vCPU') {
          if (value > 0) {
            let server = this.selectedServer
            if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
              server.configuration[server.configuration.os] = server.configuration['vCPU']
            }
          } 
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setSelectedConfiguration"]),
    setRequireField(input) {
      if (input === 'vCPU') {
        return this.requireCpu
      } else if (input === 'MEMORY') {
        return this.requireRam
      } else if (input === 'SSD' || input === 'HDD') {
        return this.requireStorage
      }
    },
    updateConfig(attribute, value) {
      let server = this.selectedServer;
      server.configuration[attribute] = value;

      this.setSelectedConfiguration(server.configuration);
    },
    // if GOU is selected, set all other selected GPUs to 0 (Only 1 is selectable at the same time)
    updateGpusInConfig(attribute, value) {
      let server = this.selectedServer;
      for (const [key, _] of Object.entries(server.configuration)) {
        if (key === attribute) {
          continue
        } else if (key.includes('GPU-')) {
          server.configuration[key] = 0
        }
      }
      server.configuration[attribute] = value;
    },
    getImgUrl(pic) {
      return require('../../assets/'+pic)
    },
    showPopover() {
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 6000);
    },
    //needs to be adjusted for live data
    checkMaxLength(usageType) {
      if (usageType === "HDD" || usageType === "SSD" || usageType === "MEMORY") {
        let maxLength = 9999
        return maxLength
      } else {
        let maxLength = 999
        return maxLength
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.inputNumField {
  width: 60px;
  margin-top: 5px;
}
.inputFieldRequired {
  width: 60px;
  margin-top: 5px;
  border-color: red;
  border-width: 2px;
}
.sideBarElementLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
}
.collapseHeader {
  display: flex;
  text-align: left;
}
.disabled {
  opacity: 0.5;
}
</style>