<template>
  <a-spin :spinning="loading">
    <h1>Einstellungen</h1>
    <a-tabs v-model:activeKey="activeKey" type="card" style="width: 1050px; margin: auto;" @change="changeTabs">
    <a-tab-pane :key="'1'" tab="Logo">
      <div id="chooseLogo">
        <h2>Logo für die Angebotserstellung</h2>
        <a-upload
          v-model:file-list="fileList"
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :max-count=1
          :show-upload-list="true"
          accept=".png, .jpg"
          :custom-request="customRequest"
          :before-upload="beforeUpload"
          @change="handleChange"
          @preview="handlePreview"
          @remove="handleRemove"
          >
          <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <div class="ant-upload-text">Bilddatei wählen</div>
          </div>
        </a-upload>
        <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
        <a-button v-if="isButtonVisible" id="uploadButton" type="primary" @click="sendImage()"> <UploadOutlined/> Upload</a-button>
        <br>
        <h4>Hinweis: Gültige Dateiformate sind png und jpeg.</h4>
        <h4>Die maximale Breite darf 600 px und die maximale Höhe 200 px betragen.</h4>

      </div>
    </a-tab-pane>
    <a-tab-pane :key="'2'" tab="Preisliste">
      <div class="search-container">
        <div>
          <a-input-search class="searchFieldInputUsageName" v-model:value="searchInput" placeholder="Suchtext..." @change="handleSearch()" size="default" style="width: 360px;">
            <template #enterButton>
              <a-button class="resetButton" type="primary" @click="handleSearchReset()">X</a-button>
            </template>
          </a-input-search>
        </div>
      </div>
      <div class="priceTableContainer">
        <a-table class="priceTable" size="small" :showHeader="true" :columns="columns" :data-source="currentPriceList">
        <template v-slot:bodyCell="{record, column}">
          <template v-if="column.dataIndex === 'name'">
            <span>{{ record.usage_name === undefined ? record.created : record.usage_name }}</span>
          </template>
          <template v-if="column.dataIndex === 'price'">
            <span>{{ record.default_price}}</span>
          </template>
          <template v-if="column.dataIndex === 'rabatt%'">
            <span>{{ record.default_discount}}</span>
          </template>
          <template v-if="column.dataIndex === 'rabatt€'">
            <span>{{ record.default_price_discounted}}</span>
          </template>
          <template v-if="column.dataIndex === 'verkaufspreis'">
             <a-input-number style="width: 150px;" :min="0" :controls="false" decimalSeparator="," :precision="4" v-model:value="record.vk_price" :id="record.recordid.toString()"></a-input-number>
          </template>
          <template v-if="column.dataIndex === 'save'">
                <div>
                  <a-tooltip placement="right">
                    <template #title>
                      {{ 'Änderungen speichern' }}
                    </template>
                    <a-button @click="setSellingPrice(record)" type="primary" class="tableButton"><SaveOutlined /></a-button>
                  </a-tooltip>
                </div>
              </template>
        </template>
      </a-table>
      <div class="priceListInfoText">
        <h4>*Alle oben genannten Preise verstehen sich als Netto</h4>
      </div>   
     </div>       
    </a-tab-pane>
   </a-tabs>
 </a-spin>
</template>

<script setup>

  import { ref, onMounted} from 'vue'
  import { onBeforeUnmount } from 'vue'
  import { message, notification} from 'ant-design-vue'
  import { UploadOutlined, SaveOutlined } from '@ant-design/icons-vue'
  import api from '@/apis/apis'

  const fileList = ref([])
  const imageUrl = ref('')
  const previewVisible = ref(false)
  const previewImage = ref('')
  const previewTitle = ref('')
  const activeKey  = ref('1')
  const currentPriceList = ref([])
  const isButtonVisible = ref(false)
  var filteredList = []
  var tableBackup = []
  var loading = false
  var responseString
  var responseBase64
  var base64data
  var accountId
  var searchInput = ''

  onBeforeUnmount(() => {
  if (imageUrl.value) {
      URL.revokeObjectURL(imageUrl.value) // Speicher freigeben
      }
  })

  onMounted(async () => {
    accountId = getCookie('account')
    await getCustomerLogo()
     
    if(responseBase64.length > 0)
    {
      fileList.value.pop()
      fileList.value.push({
        name: 'image',
        status: 'done',
        thumbUrl: responseBase64,
        url: responseBase64
      })
    }    
  })

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  const handleCancel = () => {
    previewVisible.value = false
    previewTitle.value = ''
  }

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    previewImage.value = file.url || file.preview
    previewVisible.value = true
    previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
  }

  const customRequest = ({ file, onSuccess }) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      base64data = reader.result // Setze den Base64-String
      onSuccess(null, file) // Erfolgreicher Upload
    }

    reader.readAsDataURL(file)
  }

  const handleChange = ({ file }) => {

    if (file.status === 'error') {
      isButtonVisible.value = false
      message.error(`${file.name} konnte nicht hochgeladen werden.`)
    }

    if (file.status === 'done') {
      isButtonVisible.value = true
    }

    fileList.value = fileList.value.filter(item => item.uid !== file.uid).concat(file)
  }

  const handleRemove = async (file) => {
    fileList.value = fileList.value.filter(item => item.uid !== file.uid);
    isButtonVisible.value = false
    previewVisible.value = false
    previewTitle.value = ''
    await deleteCustomerLogo()
  }

  const beforeUpload = file => {
    
    return new Promise((resolve, reject) => {
      const img = new Image()
      const reader = new FileReader()

      reader.onload = (e) => {
        img.src = e.target.result

        img.onload = () => {
          const { width, height } = img
          if (width > 600 || height > 200) {
            message.error('Das Bild muss kleiner als 600px Breite und kleiner als 200px Höhe sein.')
            reject(new Error('Bildgröße überschreitet das Limit.'))
          } else {
            resolve(true) 
          }
        }

        img.onerror = () => {
          message.error('Fehler beim Laden des Bildes.')
          reject(new Error('Fehler beim Laden des Bildes.'))
        }
      }
      reader.readAsDataURL(file)
    })
  }

  async function sendImage(){

     accountId = getCookie('account')
     await setCustomerLogo()
     if(responseString.status == 200 && responseString.data == "true"){
        message.success("Die Datei wurde erfolgreich gespeichert.")
     }
  }

  function getCookie(cname) {
      let name = cname + "="
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
   }
  async function changeTabs (e) {
    // is Pricelist Tab selected, load the list
    if (e == 2) { await getPriceList() }
  }
  async function setCustomerLogo() {
    let params = new FormData()
    params.append("image", base64data)
    params.append("account", accountId)
    await api.setCustomerLogo(params).then(response =>{
      responseString = response
    }).catch(error => {
      console.log("Error ", error)
    })
  }

  async function getCustomerLogo() {
    let params = new FormData()
    params.append("account", accountId)
    await api.getCustomerLogo(params).then(response =>{
      responseBase64 = response.data
    }).catch(error => {
      console.log("Error ", error)
    })
  }

  async function deleteCustomerLogo() {
    let params = new FormData()
    params.append("account", accountId)
    await api.deleteCustomerLogo(params).then(response =>{
      message.success("Die Datei wurde gelöscht.")
    }).catch(error => {
      console.log("Error ", error)
    })
  }

  const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '300px',
    sorter: (a, b) => a.usage_name.localeCompare(b.usage_name),
  },
  {
    title: 'Preis in €',
    dataIndex: 'price',
    key: 'prices',
    width: '200px'
  },
  {
    title: 'Rabatt in %',
    dataIndex: 'rabatt%',
    key: 'rabatt&'
  },
  {
    title: 'EK Rabattiert',
    dataIndex: 'rabatt€',
    key: 'rabatt€'
  },
  {
    title: 'VK in €',
    dataIndex: 'verkaufspreis',
    key: 'verkaufspreis',
    width: '200px'
  },
  {
    title: 'Speichern',
    key: 'save',
    dataIndex:'save',
  }
];

async function getPriceList () {
  loading = true
  await api.listResellerPrices().then(response =>{
    searchInput = ''
    currentPriceList.value = response.data
    tableBackup = response.data
    loading = false
  }).catch(error => {
    loading = false
    console.log("error account",error)
  })
  
}

async function setSellingPrice (record) {
   loading = true 
   var input = document.getElementById(record.recordid)
   if(input.value == "0.0000" || input.value == "" || input.value == undefined || parseInt(input.value) < 0){
    notification.error({
      message: 'Fehler!',
      description: 'Bitte gegen Sie einen Wert, größer als 0 €, ein',
      duration: 4
    });
    input.value = record.vk_price
    return
   }
   let params = new FormData()
   params.append("recordId", record.recordid)
   params.append("price", input.value)
   await api.setVkPrice(params).then(response =>{
     loading = false
     notification.success({
        message: 'Erfolg!',
        description: 'Verkaufspreis wurde gespeichert',
        duration: 1,
     })
    }).catch(error => {
      loading = false
      console.log("Error ", error)
    })
}

function handleSearch () {
      filteredList = tableBackup.filter( x => x.usage_name.toString().toLowerCase().includes(searchInput.toString().toLowerCase()))
      currentPriceList.value = filteredList
      if (searchInput === '') {
        currentPriceList.value = tableBackup
      }

    }

 function handleSearchReset() {
      searchInput = ''
      currentPriceList.value = tableBackup
    }


</script>

<style scoped>
.search-container {
  display: flex;
  justify-content: right;
  padding-right: 48px;
} 

.avatar-uploader > .ant-upload {
  width: 256px;
  height: 256px;
}
.ant-upload-select-picture-card i {
  font-size: 64px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 16px;
  color: #666;
}

#uploadButton {
  margin-right: 11%;
}

.priceListInfoText{
  position: relative;
  bottom: 60px;
  right: 350px;
}

</style>