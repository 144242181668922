<template>
    <div class="centering" style="padding-top: 40px;">
      <div class="tutorialContainer">
        <a-collapse id="testy" v-model:activeKey="activeKey" accordion style="font-weight: bold;" @change="switchPanel(activeKey)">
          <a-collapse-panel id="testy2" key="1" header="Alerts für virtuelle Maschinen einrichten">
            <video id="video1" class="heightWidth100" controls>
              <source src="../videos/Video_alert_Disk_80_prozent_mIT_Cloud_SM.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="Erstellen des Cloud Office in der mIT Cloud">
            <video id="video2" class="heightWidth100" controls>
              <source src="../videos/Video_cloud_office_installer_mIT_Cloud_SM.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="3" header="Einrichten eines Loadbalancers über Firewallregeln">
            <video id="video3" class="heightWidth100" controls>
              <source src="../videos/Video_Loadbalancer_mIT_Cloud_SM.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="4" header="Einrichten eines Alerts für eine virtuelle Maschine">
            <video id="video4" class="heightWidth100" controls>
              <source src="../videos/Video_Monitoring_mIT_Cloud_SM.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="5" header="Einrichten von Virtual Private Cloud mit zwei Netzwerken">
            <video id="video5" class="heightWidth100" controls>
              <source src="../videos/Video_netzwerke_erstellen_SM.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="6" header="Einrichten einer Portweiterleitung auf einem Webserver">
            <video id="video6" class="heightWidth100" controls>
              <source src="../videos/Video_Portweiterleitung_mIT_Cloud_SM.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="7" header="Einstellen der Proxy in der mIT Cloud">
            <video id="video7" class="heightWidth100" controls>
              <source src="../videos/Video_proxy_mIT_SM.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="8" header="Erstellen einer Client-to-Site-VPN in der mIT Cloud">
            <video id="video8" class="heightWidth100" controls>
              <source src="../videos/Video_client_to_site_vpn_erstellen.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="9" header="Erstellen einer Site-to-Site-VPN in der mIT Cloud">
            <video id="video9" class="heightWidth100" controls>
              <source src="../videos/Video_Vpn_site_to_site_erstellen.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="10" header="Erstellen einer Terminal VM in der mIT Cloud">
            <video id="video10" class="heightWidth100" controls>
              <source src="../videos/Video_terminal_vm_erstellen.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="11" header="Automatischen Schutz für eine VM einrichten">
            <video id="video10" class="heightWidth100" controls>
              <source src="../videos/Video_Cyber_Cloud_Automatischen_Schutz_fuer_eine_VM_einrichten.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
          <a-collapse-panel key="12" header="Durchführung einer Terra Cloud Migration mit Acronis Recovery">
            <video id="video10" class="heightWidth100" controls>
              <source src="../videos/Video_Acronis_Recovery.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'Tutorials',
    data () {
      return {
        activeKey: 0,
        prevKey: 0
      }
    },
    methods: {
      switchPanel(key){
        if (this.prevKey !== 0) {
          var videoToPause = document.getElementById("video"+this.prevKey)
          if (videoToPause !== null) {
            videoToPause.pause()
          }
        }
        this.prevKey = key
      },
    }
  }
  </script>
  
  <style scoped>
  
  .centering {
    width: 100%;
    user-select: none;
    flex-direction: column;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tutorialContainer {
    width: 40%;
    min-width: 450px;
  }
  .heightWidth100 {
    width: 100%;
    height: 100%;
  }
  .selectButton {
    display: flex; 
    justify-content: left; 
    flex-direction: column; 
    margin-bottom: 1%;
    margin-top: 40px;
  }
  @media (max-width: 500px) {
  .tutorialContainer { 
    min-width: 350px;
  }
}
  </style>