<template>
    <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <div>Endpoint</div>
        <a-switch style="width: 70px;" checked-children="Gruppe" un-checked-children="Einzeln" v-model:checked="model"></a-switch>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapMutations } from "vuex";
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
  
  export default {
    name: "CyberCloudFormField",
    components: {
      ExclamationCircleOutlined
    },
  
    data() {
         return {
  
        };
    },
    computed: {
      ...mapGetters(["selectedServer"]),
      model : {
        get () {
            if (this.selectedServer.endpointType === 'Einzeln'){
                this.updateConfig("Cyber_Cloud_Endpoint", 1);
                return false 
            }
            if (this.selectedServer.endpointType === 'Gruppe'){
                return true 
            }
             
        },
        set(value) {
            let server = this.selectedServer;
            if (value === true) {
                if (server.name.includes('Cyber Cloud Endpoint')){
                    this.selectedServer.name = server.name.replace("Endpoint", "Endpoint Gruppe")
                }    
                this.selectedServer.endpointType = "Gruppe"
            }
            if (value === false) {
                if (server.name.includes('Cyber Cloud Endpoint Gruppe')){
                    this.selectedServer.name = server.name.replace("Endpoint Gruppe", "Endpoint")
                }    
                this.selectedServer.endpointType = "Einzeln"
            }
        }
      }
    },
    methods: {
      ...mapMutations(["setSelectedConfiguration"]),
      updateConfig(attribute, value) {
        let server = this.selectedServer;
        server.configuration[attribute] = value;  
        this.setSelectedConfiguration(server.configuration);
      },     
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .inputNumField {
    width: 60px;
    margin-top: 5px;
  }
  .inputFieldRequired {
    width: 60px;
    margin-top: 5px;
    border-color: red;
    border-width: 2px;
  }
  .sideBarElementLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
  }
  .collapseHeader {
    display: flex;
    text-align: left;
  }
  .disabled {
    opacity: 0.5;
  }
  </style>