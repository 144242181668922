<template>
    <div class="background">
        <Header></Header>
        <Content></Content>
        <Footer></Footer>
    </div>
</template>


<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import Content from "@/components/Content.vue"

export default {
    name: 'GlobalLayout',
    components: {
        Header,
        Footer,
        Content
    }
}
</script>

<style >
.background {
    display: flex;
    flex-direction: column;
    height: 100vh;
    top: 0;
    left: 0;
    background-image: url('../assets/Group-3581.svg');
    background-position: center;
    background-color: rgba(181,181,181,0.05)
}
</style>