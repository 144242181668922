<template>
  <div>
    <img src="../assets/mittelDeutsche_header_banner2.svg" width="236" height="50" alt="mIT">
    <div>Redirecting...</div>
  </div>
</template>

<script>
  import api from '@/apis/apis'
  import { VueElement} from 'vue'

  export default {
    VueElement,
    data(){
      return{
        cookieResult:''
      }
    },
     async mounted() {
       await this.fetchData()
    },
     methods: {
      async  fetchData(){
        await this.setloginCookiePortal()
        if(this.cookieResult === true){
          await this.loginPortal()
        }
      },
      async loginPortal(){
        await api.getAccountByCookie().then(response =>{
          document.cookie = "account="+response.data.account+";SameSite=None;Secure"
          this.$store.commit('setAccountUuid', response.data.account)
          document.cookie = "authenticated=true;SameSite=None;Secure"
          this.$router.push({ path: '/globallayout'})
        }).catch(error =>{
          console.log("Status Error: "+error.response.status+" "+error.response.statusText)
          document.cookie = "authenticated=false;SameSite=None;Secure"
          window.location.href = "https://login.mitteldeutsche-it.de/"
        })
      },
       async setloginCookiePortal(){
         await api.setCookieLocally().then(response =>{
           document.cookie = "session_token="+response.data+";SameSite=None;Secure"
           document.cookie = "authenticated=true;SameSite=None;Secure"
           this.cookieResult = true
         }).catch(error =>{
           console.log("Status Error: "+error.response.status+" "+error.response.statusText)
           this.cookieResult = false
           document.cookie = "session_token=;SameSite=None;Secure"
           document.cookie = "authenticated=false;SameSite=None;Secure"
           window.location.href = "https://login.mitteldeutsche-it.de/"
         })
      }
  },
}
</script>
