<template>
  <a-spin :spinning="loading">
    <!-- Delete Modal -->
    <delete-account-and-user-form
      :resourceToDelete="resourceIdent"
      :deletionModal="deleteModalBoolean"
      :resourceName="resourceName"
      :resourceUuid="resourceUuid"
      :userDomainId="userDomainId"
      :accountName="accountName"
      :configuratorId="configuratorId"
      @close-modal="closeModal"
      @reload-user="listUsersForAccounts"
      @reload-accounts="listAccountsForReseller"
      @loadConfigs="loadConfigs"/>
    <!-- EDIT User Modal -->
    <EditUsers
      :edit-user-modal="showEditUser"
      :user-resource="userObj"
      @close-modal="closeModal"
      @reload-user="listUsersForAccounts"/>
    <!-- EDIT Account Modal -->
     <EditAccount
      :accountResource="accountObj"
      :editAccountModal="showEditAccount"
      @close-modal="closeModal"
      @reload-accounts="listAccountsForReseller"/>
    <!-- <a-modal
      style="top: 20px;"
      v-model:open="showEditAccount"
      :title="'Benutzerkonto bearbeiten'"
      :closable="true"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleEditAccSubmit"
      centered>
      <a-form
        :ref="formRefEditAcc"
        :model="formEditAcc"
        :rules="rulesEditAcc"
        :loading="loading"
        layout="vertical"
        @finish="handleEditAccSubmit">
        <a-form-item ref="accountName" name="accountName">
          <template #label>
            <TooltipLabel :title="'Neuer Name '" :tooltip="'Wählen Sie einen neuen Namen für das Benutzerkonto. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
          </template>
          <a-input v-model:value="formEditAcc.accountName" :placeholder="'Name des Benutzerkontos'" />
        </a-form-item>
      </a-form>
    </a-modal> -->
    <!-- CREATE Account Modal -->
    <a-modal
      style="height: 65em;"
      v-model:open="showCreateAccount"
      :title="'Benutzerkonto erstellen'"
      :closable="false"
      :maskClosable="false"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleSubmit"
      destroyOnClose
      :confirmLoading="loading"
      centered>
        <a-form
          :ref="formRef"
          :model="form"
          :rules="rules"
          :loading="loading"
          layout="vertical"
          @finish="handleSubmit">
          <!-- WP Implementation -->
          <a-form-item ref="account" name="account">
            <template #label>
              <TooltipLabel :title="'Name des Benutzerkontos'" :tooltip="'Name des eigentlichen Benutzerkontos, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
            </template>
            <a-input v-model:value="form.account" :placeholder="'Name des Benutzerkontos'" />
          </a-form-item>
          <a-form-item ref="username" name="username">
            <template #label>
              <tooltip-label :title="'Firmenname'" :tooltip="'Firmenname, welcher in den Rechnungen angezeigt wird. Alle Sonderzeichen sind erlaubt, da dies nur ein reiner Anzeige-Name und sonst nicht für das System relevant ist.'"/>
            </template>
            <a-input
              v-model:value="form.username"
              placeholder="Name der Firma" />
          </a-form-item>
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="password" name="password">
                <template #label>
                  <tooltip-label :title="'Passwort'" :tooltip="'Mindestanforderungen: \n- Mindestens 8 Zeichen\n- Mindestens 1 Großbuchstaben\n- Mindestens 1 Kleinbuchstaben\n- Mindestens 1 Zahl\n- Mindestens 1 Sonderzeichen : \n\t!?@#$%^&*()\-_=+{};:,<.>\n- Keine Buchstabe darf mehr als zweimal hintereinander stehen!'"/>
                </template>
                <a-input-password
                  v-model:value="form.password"
                  :placeholder="'Passwort'"/>
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item ref="confirmpassword" name="confirmpassword">
                <template #label>
                  <TooltipLabel :title="'Passwort bestätigen'" :tooltip="'Bitte wiederholen Sie das Passwort'"/>
                </template>
                <a-input-password
                  v-model:value="form.confirmpassword"
                  :placeholder="'Passwort bestätigen'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- WP Implementation -->
          <!-- Customer NR -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="customerID" name="customerID">
                <template #label>
                  <tooltip-label :title="'Kundennummer'" :tooltip="'Bitte geben Sie eine Kundennummer an.\nErlaubte Zeichen: \nZahlen und -_'"/>
                </template>
                <a-input
                  v-model:value="form.customerID"
                  :placeholder="'Kundennummer'" />
              </a-form-item>
            </a-col>
            <!-- Contract NR -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="contractNumber" name="contractNumber">
                <template #label>
                  <tooltip-label :title="'Vertragsnummer'" :tooltip="'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'"/>
                </template>
                <a-input
                  v-model:value="form.contractNumber"
                  :placeholder="'Vertragsnummer'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- Street + Nr -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="17">
              <a-form-item ref="street" name="street">
                <template #label>
                  <tooltip-label :title="'Straße + Hausnummer'" :tooltip="'Bitte geben Sie eine gültige Adresse an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                v-model:value="form.street"
                  :placeholder="'Straße + Hausnummer'"/>
              </a-form-item>
            </a-col>
            <!-- Postal Code -->
            <a-col :md="24" :lg="7">
              <a-form-item ref="postalcode" name="postalcode">
                <template #label>
                  <tooltip-label :title="'PLZ'" :tooltip="'Bitte geben Sie eine Postleitzahl an.\nPostleitzahlen haben maximal 8 Ziffern.'"/>
                </template>
                <a-input
                v-model:value="form.postalcode"
                  :placeholder="'PLZ'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- City -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="city" name="city">
                <template #label>
                  <tooltip-label :title="'Stadt'" :tooltip="'Bitte geben Sie einen Stadtnamen an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                v-model:value="form.city"
                  :placeholder="'Stadt'"/>
              </a-form-item>
            </a-col>
            <!-- Country -->
            <a-col :md="24" :lg="12">
              <a-form-item ref="country" name="country">
                <template #label>
                  <tooltip-label :title="'Land'" :tooltip="'Bitte geben Sie ein gültiges Land an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, \nLeerzeichen und -'"/>
                </template>
                <a-input
                  v-model:value="form.country"
                  :placeholder="'Land'"/>
              </a-form-item>
            </a-col>
          </a-row>
          <!-- Email -->
          <a-form-item ref="email" name="email">
            <template #label>
              <tooltip-label :title="'E-Mail'" :tooltip="'Bitte geben Sie eine gültige E-Mail an.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_@.'"/>
            </template>
            <a-input
              v-model:value="form.email"
              :placeholder="'E-Mail'" />
          </a-form-item>
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="firstname" name="firstname">
                <template #label>
                  <tooltip-label :title="'Vorname'" :tooltip="'Bitte geben Sie den Vorname des Nutzers an.'"/>
                </template>
                <a-input
                  v-model:value="form.firstname"
                  :placeholder="'Vorname'" />
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item ref="lastname" name="lastname">
                <template #label>
                  <tooltip-label :title="'Nachname'" :tooltip="'Bitte geben Sie den Nachname des Nutzers an.'"/>
                </template>
                <a-input
                  v-model:value="form.lastname"
                  :placeholder="'Nachname'" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-switch style="margin-left: 5px; margin-right: 5px;" v-model:checked="createDomainBool" size="small" @change="setCreateDomain"></a-switch>Neue Domain erstellen?
          <a-form-item ref="newDomainName" name="newDomainName" v-if="createDomainBool">
            <template #label>
              <tooltip-label :title="'Domain'" :tooltip="'Erstellen Sie eine neue Domain oder wählen Sie aus bereits vorhandenen Domains aus. Das Benutzerkonto wird unter der angegeben Domain erstellt!'"/>
            </template>
            <div>
              <a-input v-model:value="form.newDomainName" :placeholder="'Name der neuen Domain'" />
            </div>
          </a-form-item>
          <a-form-item ref="domainid" name="domainid" v-else>
            <template #label>
              <tooltip-label :title="'Domain'" :tooltip="'Erstellen Sie eine neue Domain oder wählen Sie aus bereits vorhandenen Domains aus. Das Benutzerkonto wird unter der angegeben Domain erstellt!'"/>
            </template>
            <div>
              <a-select
              v-model:value="form.domainid"
              :placeholder="'Domain auswählen'"
              showSearch
              optionFilterProp="label"
              :filterOption="(input, option) => {
                return  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }" >
              <a-select-option v-for="domain in domainsList" :key="domain.ID" :label="domain.Path || domain.Name">
                <span>
                  <BlockOutlined style="margin-right: 5px"/>
                  {{ domain.Path || domain.Name  }}
                </span>
              </a-select-option>
            </a-select>
            </div>
          </a-form-item>
      </a-form>
    </a-modal>
    <!-- CREATE User Modal -->
    <a-modal
      style="height: 50em;"
      v-model:open="showCreateUser"
      :title="'Benutzer erstellen'"
      :closable="false"
      :maskClosable="false"
      cancelText="Abbrechen"
      @cancel="closeModal"
      @ok="handleUserSubmit"
      destroyOnClose
      :confirmLoading="loading"
      centered>
      <a-form
          :ref="formRefCreateUser"
          :model="formCreateUser"
          :rules="rulesCreateUser"
          :loading="loading"
          layout="vertical"
          @finish="handleUserSubmit">
        <!-- WP Implementation -->
        <a-form-item ref="userName" name="userName">
          <template #label>
            <TooltipLabel :title="'Name des Benutzers'" :tooltip="'Name des eigentlichen Benutzer, welches vom System benutzt wird. Keine Sonderzeichen erlaubt um Requestfehler zu verhindern.'"/>
          </template>
          <a-input v-model:value="formCreateUser.userName" :placeholder="'Name des Benutzer'" />
        </a-form-item>
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="userPassword" name="userPassword">
              <template #label>
                <tooltip-label :title="'Passwort'" :tooltip="'Mindestanforderungen: \n- Mindestens 8 Zeichen\n- Mindestens 1 Großbuchstaben\n- Mindestens 1 Kleinbuchstaben\n- Mindestens 1 Zahl\n- Mindestens 1 Sonderzeichen : \n\t!?@#$%^&*()\-_=+{};:,<.>\n- Keine Buchstabe darf mehr als zweimal hintereinander stehen!'"/>
              </template>
              <a-input-password v-model:value="formCreateUser.userPassword" :placeholder="'Passwort'"/>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item ref="userConfirmPassword" name="userConfirmPassword">
              <template #label>
                <TooltipLabel :title="'Passwort bestätigen'" :tooltip="'Bitte wiederholen Sie das Passwort.'"/>
              </template>
              <a-input-password v-model:value="formCreateUser.userConfirmPassword" :placeholder="'Passwort bestätigen'"/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- WP Implementation -->
        <!-- City -->
        <a-row :gutter="12">
          <!-- Email -->
          <a-col :md="24" :lg="12">
            <a-form-item ref="userEmail" name="userEmail">
              <template #label>
                <tooltip-label :title="'E-Mail'" :tooltip="'Bitte geben Sie die E-Mail des Nutzers an.\nEs muss eine gültige Email sein.\nErlaubte Zeichen: \nBuchstaben, Zahlen, -_@.'"/>
              </template>
              <a-input
                  v-model:value="formCreateUser.userEmail"
                  :placeholder="'E-Mail'" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="userFirstname" name="userFirstname">
              <template #label>
                <tooltip-label :title="'Vorname'" :tooltip="'Bitte geben Sie den Vornamen des Nutzers an.'"/>
              </template>
              <a-input
                  v-model:value="formCreateUser.userFirstname"
                  :placeholder="'Vorname'" />
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item ref="userLastname" name="userLastname">
              <template #label>
                <tooltip-label :title="'Nachname'" :tooltip="'Bitte geben Sie den Nachnamen des Nutzers an.'"/>
              </template>
              <a-input
                  v-model:value="formCreateUser.userLastname"
                  :placeholder="'Nachname'" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  <div class="parentContainer">
    <a-tabs v-model:activeKey="activeKey" type="card" @change="loadConfigs">
      <a-tab-pane :key="'1'" tab="Benutzerkonten">
        <div class="customerHeaderContainer">
          <div class="searchFieldCustomers">
            <a-tooltip placement="top">
              <template #title>
                {{ 'Benutzerkonto erstellen' }}
              </template>
              <a-button id="createAccountBtn" class="createAccountButton" @click="showCreateAccountModal"><PlusOutlined /></a-button>
            </a-tooltip>
            <div>
             <a-input-search class="searchFieldInputCustomers" v-model:value="searchInput" placeholder="Suchtext..." @change="handleSearch()" size="default" >
              <template #enterButton>
                <a-button class="resetButton" type="primary" @click="handleSearchReset()">X</a-button>
              </template>
             </a-input-search>
            </div>
         </div>
        </div>
        <div  class="customerTableContainer">
          <a-table class="customerTable" :loading="accountLoading" size="small" :showHeader="false" :columns="accountCols" :data-source="accountData" :rowKey="record => record.id" :expandedRowKeys="expandedKeys" :expandRowByClick="true" :expand-column-width="100" :rowClassName="parentRowClassName" @expand="fetchUsers" :pagination="{ onChange: () => {expandedKeys = []} }">
            <template v-slot:expandedRowRender="{record}">
              <a-tabs v-model:activeKey="activeKey" type="card">
                <a-tab-pane :key="'1'" tab="Benutzer">
                  <a-tooltip placement="top">
                    <template #title>
                      {{ 'Benutzer erstellen' }}
                    </template>
                    <a-button class="createUserButton" id="createUserBtn" @click="showCreateUserModal(openAccount,openDomainUuid)"><UserAddOutlined/>Benutzer erstellen</a-button>
                    </a-tooltip>
                    <a-table class="userTable" :loading="userLoading" size="small" :columns="userCols" :data-source="userData">
                      <template v-slot:bodyCell="{record, column}">
                        <template v-if="column.dataIndex === 'edit'">
                          <div>
                            <a-tooltip placement="left">
                              <template #title>
                                {{ 'Benutzer bearbeiten' }}
                              </template>
                              <a-button @click="showEditUserModal(record.email, record.firstname, record.lastname, record.username, record.id, record.account, record.domainid)" class="tableButton editButton"><EditOutlined /></a-button>
                            </a-tooltip>
                            <a-tooltip placement="right">
                              <template #title>
                                {{ 'Benutzer löschen' }}
                              </template>
                              <a-button @click="showDeleteAccountOrUserModal($event, record.id, 'Benutzer', record.username, record.domainid, record.account, '')" type="primary" class="tableButton"><DeleteOutlined /></a-button>
                            </a-tooltip>
                          </div>
                        </template>
                      </template>
                    </a-table>
            </a-tab-pane>
          </a-tabs>
        </template>
        <template v-slot:bodyCell="{record, column}">
          <template v-if="column.dataIndex === 'edit'">
            <div>
              <a-tooltip placement="left">
                <template #title>
                  {{ 'Benutzerkonto bearbeiten' }}
                </template>
                <a-button @click="showEditAccountModal(record.domainid, record.id, record.name, $event)" class="tableButton editButton"><EditOutlined /></a-button>
              </a-tooltip>
              <a-tooltip placement="right">
                <template #title>
                  {{ 'Benutzerkonto löschen' }}
                </template>
                <a-button @click="showDeleteAccountOrUserModal($event, record.id, 'Benutzerkonto', record.name, '', record.name, '')" type="primary" class="tableButton"><DeleteOutlined /></a-button>
              </a-tooltip>
            </div>
          </template>
        </template>
        </a-table>
        </div>
      </a-tab-pane>
      <a-tab-pane :key="'2'" tab="Angebote">
        <div class="search-container">
          <a-input-search class="searchFieldInputOffers" v-model:value="searchInput" placeholder="Suchtext..." @change="handleOffersSearch()" size="default" style="padding-right: 45px; width: 40%;" >
            <template #enterButton>
              <a-button class="resetButton" type="primary" @click="handleSearchReset()">X</a-button>
            </template>
          </a-input-search>
        </div>
        <div  class="offersTableContainer">
          <a-table :loading="accountLoading" class="configTable" size="small" :showHeader="true" :columns="configCols" :data-source="currentConfigList" @change="handleTableChange">
            <template v-slot:bodyCell="{record, column}">
              <template v-if="column.dataIndex === 'configName'">
                <span>{{ record.configName === undefined ? record.created : (record.configName === '' ? record.created : record.configName) }}</span>
              </template>
              <template v-if="column.dataIndex === 'customer'">
                <span>{{ record.customer === '' ? record.created : record.customer }}</span>
              </template>
              <template v-if="column.dataIndex === 'configNumber'">
                <span>{{ record.configNumber }}</span>
              </template>
              <template v-if="column.dataIndex === 'state' ">
                <a-popover placement="left" :key="reloadList">
                  <template #content>
                    <span>Zum Status ändern hier klicken!</span>
                  </template>
                  <a-select
                    :defaultValue="record.state"
                    :bordered="false"
                    :showArrow="false"
                    :key="reloadList"
                    style="width: 140px;"
                    @change="handleStatusChange(record.record, $event)">
                    <a-select-option
                      v-for="status in statusList"
                      :key="status.status">
                      <!-- <a-badge :color="status.color" :title="status.status" count=" " size="large" ></a-badge> -->
                      <a-tag style="cursor: pointer;" :color="status.color">
                        {{ status.status }}
                      <template #icon>
                        <check-circle-outlined v-if="status.status === 'Neu'" :spin="status.spin" />
                        <sync-outlined v-else-if="status.status === 'Wartend'" :spin="status.spin" />
                        <euro-outlined v-else-if="status.status === 'Beauftragt'" :spin="status.spin" />
                        <minus-circle-outlined v-else-if="status.status === 'Geschlossen'" :spin="status.spin" />
                      </template>
                      </a-tag>
                    </a-select-option>
                  </a-select>
                </a-popover>
              </template>

              <template v-if="column.dataIndex === 'edit'">
                <div>
                  <a-tooltip placement="left">
                    <template #title>
                      {{ 'Angebot bearbeiten' }}
                    </template>
                    <a-button :loading="disabledLoading" @click="editOffer(record)" class="tableButton editButton"><span v-if="!disabledLoading"><EditOutlined /></span></a-button>
                  </a-tooltip>
                  <a-tooltip placement="right">
                    <template #title>
                      {{ 'Angebot herunterladen' }}
                    </template>
                    <a-button @click="getOffer(record)" class="tableButton"><FilePdfOutlined /></a-button>
                  </a-tooltip>
                  <a-tooltip placement="right">
                    <template #title>
                      {{ 'Angebot löschen' }}
                    </template>
                    <a-button @click="showDeleteAccountOrUserModal($event, '', 'Angebot', record.configName, '', '',  record.record)" type="primary" class="tableButton"><DeleteOutlined /></a-button>
                  </a-tooltip>
                </div>
              </template>
            </template>
          </a-table>
        </div>
     </a-tab-pane>
   </a-tabs>
  </div>
</a-spin>
</template>

<script>

import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import { DeleteOutlined, EditOutlined, BlockOutlined, PlusOutlined, UserAddOutlined, ExportOutlined, FilePdfOutlined,CheckCircleOutlined,
  SyncOutlined,MinusCircleOutlined,EuroOutlined  } from '@ant-design/icons-vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import DeleteAccountAndUserForm from '../components/forms/DeleteAccountAndUserForm.vue'
import EditUsers from '../components/forms/EditUsers.vue'
import EditAccount from '../components/forms/EditAccount.vue'
import { message, notification } from 'ant-design-vue'

export default {
  setup () {
    const activeKey  = ref('1')
    return {
      activeKey
    }
  },
  components: {
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    BlockOutlined,
    TooltipLabel,
    PlusOutlined,
    DeleteAccountAndUserForm,
    EditUsers,
    EditAccount,
    UserAddOutlined,
    FilePdfOutlined,
    notification,
    CheckCircleOutlined,
    SyncOutlined,
    MinusCircleOutlined,
    EuroOutlined
  },
  name: 'Customers',
  data () {
    return {
      accountCols: [
        {
          key: 'name',
          dataIndex: 'name',
          width: '40%',
        },
        {
          key: 'edit',
          dataIndex: 'edit',
          align: 'right'
        }
      ],
      accountName: '',
      accountid: '',
      accountLoading: false,
      disabledLoading: false,
      accountData: [],
      accountObj: {
        accountname: undefined,
        domainuuid: undefined,
        accountuuid: undefined
      },
      createAccountModal: false,
      createDomainBool: true,
      currentConfigList: [],
      domainsList: [],
      deleteModalBoolean: false,
      emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
      passwordRegexUpperCase: /^(?=.*?[A-Z]).*$/, //minimum 1 Upper Case Letter
      passwordRegexLowerCase: /^(?=.*?[a-z]).*$/, //minimum 1 Lower Case Letter
      passwordRegexDigit: /^(?=.*?\d).*$/, //minimum 1 Digit
      passwordRegexSpecialCharacter: /^(?=.*?[!?@#$%^&*()\-_=+{};:,<.>]).*$/, //minimum 1 Special Character
      passwordRegexRepeatCharacter: /^(?:(.)(?!\1{2}))+$/, //not more than 2 repeated Letters
      passwordRegexWrongSpecials: /[`´€µ°²³§[\]'"|,/ß~]/, // special Characters that are not allowed
      passwordRegexLength: /^([A-Za-z0-9!?@#$%^&*()\-_=+{};:,<.>]{8,})$/, //minimum 8 Chars
      customerIdRegex: /^[0-9]*$/, //only digits
      contractNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/, //only letters, digits, -_.
      cityNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9- ]*$/,
      expandedKeys: [],
      loading: false,
      openAccount: undefined,
      openDomainUuid: undefined,
      resourceIdent: '',
      resourceName: '',
      resourceUuid: '',
      configuratorId: '',
      searchInput: '',
      domainUuid: undefined,
      showCreateAccount: false,
      showCreateUser: false,
      showEditAccount: false,
      showEditUser: false,
      specialChars: /[`´!@€µ°²³#$%^§&*()+=[\]{};':"\\|,<>/?ß~]/,
      specialCharsMail: /[-_@.]/,
      reloadList: 1,
      statusList: [
        {
          id: 1,
          status: 'Neu',
          color: 'blue',
          spin: false,
        },
        {
          id: 2,
          status: 'Wartend',
          color: 'yellow',
          spin: true,
        },
        {
          id: 3,
          status: 'Beauftragt',
          color: 'green',
          spin: false,
        },
        {
          id: 4,
          status: 'Geschlossen',
          color: 'grey',
          spin: false,
        },
      ],
      tableBackup: [],
      tableOffersBackup: [],
      userCols: [
        {
          title: 'Benutzer',
          key: 'username',
          dataIndex:'username',
          width: "67.7%"
        },
        {
          title: 'Einstellungen (Benutzer)',
          key: 'edit',
          dataIndex:'edit',
          width: '12em'
        }
      ],
      configCols: [
        {
          title: 'Angebotsname',
          key: 'configName',
          dataIndex:'configName',
          width: "190px",
          sorter: (a, b) => a.configName.localeCompare(b.configName)
        },
        {
          title: 'Angebotsnummer',
          key: 'configNumber',
          dataIndex:'configNumber',
          width: "160px",
        },
        {
          title: 'Customer',
          key: 'customer',
          dataIndex:'customer',
          width: '189px',
          sorter: (a, b) => a.customer.localeCompare(b.customer)
        },
        {
          title: 'Status',
          key: 'state',
          dataIndex:'state',
          width: "153px",
        },
        {
          title: 'Datum',
          key: 'created',
          dataIndex:'created',
          width: '144px'
        },
        {
          title: 'Aktion',
          key: 'edit',
          dataIndex:'edit',
          width: '200px'
        }
      ],
      userData: [],
      userDomainId: '',
      userLoading: false,
      userObj: {
        username: undefined,
        firstname: undefined,
        lastname: undefined,
        email: undefined,
        userUuid: undefined,
        account: undefined,
        domainid: undefined
      },
      year: new Date().getFullYear(),
    }
  },
  created () {
    this.initForm()
  },
  async mounted () {
    await this.listAccountsForReseller()
  },
  methods: {
    parentRowClassName(record) {
      return this.expandedKeys.includes(record.id) ? 'expanded-parent-row' : '';
    },
    initForm () {
      this.formRefCreateUser = ref()
      this.formCreateUser =  reactive({
        userName: undefined,
        userEmail: undefined,
        userPassword: undefined,
        userConfirmPassword: undefined,
        userFirstname: undefined,
        userLastname: undefined
      })
      this.rulesCreateUser = reactive({
        userName: [
          { required: true, message: 'Bitte geben Sie einen Benutzernamen an.' },
          { validator: this.validateAccountName }
        ],
        userEmail: [
          { required: true, message: '' },
          { validator: this.validateEmail }
        ],
        userFirstname: [{ required: true, message: 'Bitte geben Sie den Vornamen des Nutzers an.' }],
        userLastname: [{ required: true, message: 'Bitte geben Sie den Nachnamen des Nutzers an.' }],
        userPassword: [
          { required: true, message: '' },
          { validator: this.validateUserPassword }
        ],
        userConfirmPassword: [
          { required: true, message: '' },
          { validator: this.validateConfirmUserPassword }
        ],
      })
      this.formRef = ref()
      this.form = reactive({
        domainid: undefined,
        newDomainName: undefined,
        account: undefined,
        city: undefined,
        postalcode: undefined,
        street: undefined,
        country: undefined,
        customerID: undefined,
        contractNumber: undefined
      })
      this.rules = reactive({
        roleid: [{ required: true, message: 'Bitte eine Option auswählen' }],
        account: [
          { required: true, message: '' },
          { validator: this.validateAccountName }
        ],
        newDomainName: [{ required: true, message: 'Bitte geben Sie einen Domainnamen an.' }],
        domainid: [{ required: true, message: 'Bitte wählen Sie eine Domain aus.' }],
        username: [
          { required: true, message: 'Bitte geben Sie den Namen der Firma an.' },
          { validator: this.validateCompanyName }
        ], //Firmname
        password: [
          { required: true, message: '' },
          { validator: this.validatePassword }
        ],
        confirmpassword: [
          { required: true, message: '' },
          { validator: this.validateConfirmPassword }
        ],
        city: [
          { required: true, message: 'Bitte geben Sie eine Stadt an.' },
          { validator: this.validateCity }
        ],
        postalcode: [
          { required: true, message: 'Bitte geben Sie eine Postleitzahl an.'},
          { validator: this.validatePlz }
        ],
        street: [
          { required: true, message: 'Bitte geben Sie eine Adresse an.' },
          { validator: this.validateStreet }
        ],
        country: [
          { required: true, message: 'Bitte geben Sie ein Land an.' },
          { validator: this.validateCountry }
        ],
        customerID: [
          { required: true, message: 'Bitte geben Sie eine Kundennummer an.' },
          { validator: this.validateCustomerID }
        ],
        email: [
          { required: true, message: '' },
          { validator: this.validateEmail }
        ],
        firstname: [{ required: true, message: 'Bitte geben Sie einen Vornamen an.' }],
        lastname: [{ required: true, message: 'Bitte geben Sie einen Nachnamen an.' }],
        domain: [{ required: true, message: 'Bitte eine Option auswählen' }],
        contractNumber: [
          { required: false, message: 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.' },
          { validator: this.validateContractNumber }
        ]
      })
    },
    async loadConfigs (newActiveKey) {
      if (newActiveKey == '2') {
        this.accountLoading = true
        var params = new FormData()
        params.append('resellerUuid', this.accountid)
        await api.listConfigurator(params).then(response =>{
          this.currentConfigList = response.data
          this.tableOffersBackup = response.data
          this.accountLoading = false
        }).catch(error => {
          console.log("error account",error)
          this.accountLoading = false
        })
        this.disabledLoading = true
        await api.getCalculatorAccountPrices(this.accountid).then(response =>{
          this.myMap = new Map(Object.entries(response.data))
        }).catch(error => {
          console.log("error account",error)
        })
        // add Popover data and Text to each Resource that needs a popover manually!
        var rawMap = toRaw(this.myMap)
        this.$store.commit('setPriceList', await this.setManualParamsToPriceMap(rawMap))
        await api.listResellerPrices().then(response =>{
          this.$store.commit('setVkPriceList', response.data)
        }).catch(error => {
          console.log("error account",error)
        })
        this.disabledLoading = false
      }
      if (newActiveKey == 'fromSetStatus') {
        this.accountLoading = true
        var params = new FormData()
        params.append('resellerUuid', this.accountid)
        await api.listConfigurator(params).then(response =>{
          this.currentConfigList = response.data
          this.tableOffersBackup = response.data
          this.accountLoading = false
        }).catch(error => {
          console.log("error account",error)
          this.accountLoading = false
        })
      }
    },
    async setManualParamsToPriceMap (hashMap) {
      for(let key of hashMap.keys()) {
        if (key.includes('NextGenerationFirewall')) {
          // add popover to NGF
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die Next-Generation-Firewall ist ein mehrstufiges Sicherheitssystem zum Schutz vor modernen Cyberattacken' }
        } else if (key.includes('IP_ADDRESS')) {
          // add popover to IP Address
          hashMap.get(key).popover = { title: 'Hinweis', content: 'Die erste IP-Adresse ist kostenlos.' }
        } else if (key.includes('MS_Exchange')) {
          // add popover and IMG to MS_Exchange licenses
          hashMap.get(key).image = 'exchange-new.svg'
        } else if (key.includes('MSSQL') && key.includes('Core_License')) {
          // add popover and IMG to MSSQL Core licenses
          hashMap.get(key).image = 'microsoft-sql-server.png'
        } else if (key.includes('windows') || key.includes('Windows')) {
          // add popover and IMG Windows licenses
          hashMap.get(key).image = 'windows_icon.png'
        }
      }
      hashMap.set('Linux', { data: { discount: 0 }, label: 'Linux', hoverLabel: 'Linux', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, image: 'linus.svg', isLicense: true, isOS: true, isResource: false  })
      hashMap.set('libre', { data: { discount: 0 }, label: 'LibreOffice Lizenz', hoverLabel: 'LibreOffice', price: 0, discount: "0.00", priceWithoutDiscount: 0, order: 0, popover: { title: null, content: null }, isLicense: true, isOS: false, isResource: false  })
      return hashMap
    },
    async fetchUsers(expanded, record) {
      var callListUser = true
      if (this.openAccount === record.name) {
        callListUser = false
      }
      this.openAccount = record.name
      this.openDomainUuid = record.domainid
      var keys = []
      if(expanded){
        keys.push(record.id)
        if (callListUser) {
          await this.listUsersForAccounts(record.name,record.domainid)
        }
      }
      this.expandedKeys = keys
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    getOffer(record){
      if((record != undefined) && (record.calculatorObject != null)) {
        var params = new FormData()
        params.append('object', JSON.stringify(record.calculatorObject))
        params.append('account', this.getCookie('account'))
        params.append('customerId', record.customerId)
        params.append('offerName', record.configName)
        params.append('offerNumber', record.configNumber)
        params.append('customerName', record.customer)
        api.getPdf(params).then(response => {
          let filename = response.headers['content-disposition'].split("filename=")[1]
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
          this.$notification.success({
            message: 'Angebot wurde erstellt!',
          })
        }).catch(error => {
          console.log(error)
        })
      }
      else{
        message.error("Kein Angebot vorhanden")
      }

    },
    async listAccountsForReseller(){
      this.accountLoading = true
      this.accountid = this.getCookie('account')
      await api.listAccounts(this.accountid).then(response =>{
        this.accountData = response.data
        this.tableBackup = response.data
        this.accountLoading = false
      }).catch(error => {
        console.log("error account",error)
        this.accountLoading = false
      })
    },
    async listUsersForAccounts(accountNameRecord,domainIdRecord){
      let params = new FormData()
      params.append('accountName', accountNameRecord)
      params.append('domainuuid',domainIdRecord)
      await api.listUsers(params).then(response =>{
        this.userData = []
        this.userData = response.data
      }).catch(error => {
        this.userData = []
        console.log("error users: ",error)
      })
    },
    listDomainsForReseller(){
      api.listDomains(this.accountid).then(response =>{
        this.domainsList = response.data
      }).catch(error => {
        console.log("error domain",error)
      })
    },
    showCreateAccountModal () {
      this.listDomainsForReseller()
      this.showCreateAccount = true
    },
    showEditAccountModal (domainUuid, accountUuid, accountName, event) {
      event.stopPropagation()
      this.accountObj.domainuuid = domainUuid
      this.accountObj.accountuuid = accountUuid
      this.accountObj.accountname = accountName
      this.showEditAccount = true
    },
    showDeleteAccountOrUserModal (event, uuid, resourceIdent, name, userDomainId, accountName, configuratorId) {
      event.stopPropagation()
      this.deleteModalBoolean = true
      this.resourceIdent = resourceIdent
      this.resourceName = name
      this.resourceUuid = uuid
      this.userDomainId = userDomainId
      this.accountName = accountName
      this.configuratorId = configuratorId.toString()
    },
    showCreateUserModal(accountName, domainuuid) {
      this.openAccount = accountName
      this.openDomainUuid = domainuuid
      this.showCreateUser = true
    },
    showEditUserModal (userEmail, userFirstname, userLastname, userName, userUuid, userAccount, userDomainid) {
      this.userObj.email = userEmail
      this.userObj.firstname = userFirstname
      this.userObj.lastname = userLastname
      this.userObj.username = userName
      this.userObj.userUuid = userUuid
      this.userObj.account = userAccount
      this.userObj.domainid = userDomainid
      this.showEditUser = true
    },
    async handleStatusChange (configId, e) {
      var statusParams =  new FormData()
      statusParams.append('id', configId)
      statusParams.append('status', e)
      await api.updateOfferStatus(statusParams).then(response => {
      }).catch(error => {
        this.$notification.error({
          message: 'Fehler beim Ändern des Angebotsstatus',
          description:
              'Der Status konnte nicht bearbeitet werden. Laden Sie die Seite neu und versuchen Sie es bitte erneut. (' + error.response.data + ')',
          duration: 0
        })
      })
      this.loadConfigs('fromSetStatus')
    },
    async fillCustomVKPrices (calcObj) {
      var pushToVkPrices = []
      for (var server of calcObj) {
        for (var resource of server.bill) {
          if (resource.resourceId === 0) {
            continue
          }
          if (pushToVkPrices.filter(vkPriceObject => vkPriceObject.id === resource.resourceId).length > 0) {
            continue
          }
          var tempobject = { id: resource.resourceId, customPrice: resource.vksinglePrice }
          pushToVkPrices.push(tempobject)
        }
      }
      this.$store.commit('overWriteCustomVkPrices', pushToVkPrices)
    },
    async setFireWallAndIps (recordInput) {
      for (var config of recordInput.calculatorObject) {
        if (config.id === 'firewall') {
          if (config.bill[0].count === 1) {
            this.$store.commit('setFirewall', true)
          } else if (config.bill[0].count === 2) {
            this.$store.commit('setFirewall', true)
            this.$store.commit('setFirewall2', true)
          }
        }
        if (config.id === 'ip') {
          this.$store.commit('setIp', config.bill[0].count)
        }
      }
    },
    async editOffer(recordInput) {
      await this.fillCustomVKPrices(toRaw(recordInput.calculatorObject))
      this.$store.commit('setConfigImportId', recordInput.record)
      this.$store.commit('setImportedConfigName', recordInput.configName)
      this.$store.commit('setImportedConfigNumber', recordInput.configNumber)
      this.$store.commit('setCustomerId', recordInput.customerId)
      this.$store.commit('setCustomerName', recordInput.customer)
      var myObj = JSON.parse(recordInput.calculatorImportObject)
      this.$store.commit('setServerList', myObj)
      await this.setFireWallAndIps(recordInput)
      // sett tabs to "calculator"
      this.$store.commit('setCurrentMenuTab', ['2'])
      this.$router.push({ path: '/prices'})
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(async () => {
        const values = toRaw(this.form)
        var newDomainUuid = undefined
        this.loading = true
        var domainCreatedSuccessfully = true
        // WP Implementation
        if (this.createDomainBool && values.newDomainName !== undefined) {
          let createDomainParams = new FormData()
          createDomainParams.append('accountuuid', this.getCookie('account'))
          createDomainParams.append('name', values.newDomainName)
          await api.createDomain(createDomainParams).then(response => {
            newDomainUuid = response.data.id
            this.$notification.success({
              message: 'Erfolg',
              description: 'Die Domain wurde erfolgreich erstellt.',
              duration: 6
            })
          }).catch(error => {
            this.$notification.error({
              message: 'Fehler beim erstellen der Domain',
              description:
                  'Die Domain konnte nicht erstellt werden. Ohne valide Domain kann das Benutzerkonto nicht erstellt werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
              duration: 0
            })
            domainCreatedSuccessfully = false
          })
        }
        if (!domainCreatedSuccessfully) {
          return
        }
        var params = new FormData()
        // Need the reseller uuid for creating the quota usages prices for the subaccounts
        params.append('reselleruuid', this.getCookie('account'))
        params.append('username', values.account)
        params.append('password', values.password)
        params.append('email', values.email)
        params.append('firstname', values.firstname)
        params.append('lastname', values.lastname)
        params.append('customer', values.customerID)
        params.append('country', values.country)
        params.append('streetName', values.street)
        params.append('plz', values.postalcode)
        params.append('city', values.city)
        params.append('customerName', values.username)
        params.append('shortName', values.account.replace(/\s+/g, ''))
        params.append('domainuuid', newDomainUuid === undefined ? values.domainid : newDomainUuid)
        if (values.contractNumber == undefined || values.contractNumber == "" ) {
          params.append('contractNumber', "")
        }else{
          params.append('contractNumber', values.contractNumber)
        }
        await api.createAccount(params).then(async response => {
          var createdAccUUID = response.data.id
          // create the AcronisUserTenant with the information from the createAccount API
          var createAcronisUserParams = new FormData()
          createAcronisUserParams.append('tenant_companyname', values.username)
          createAcronisUserParams.append('tenant_address', values.street + ', ' + values.postalcode + ' ' + values.city + ', ' + values.country)
          createAcronisUserParams.append('tenant_email', values.email)
          createAcronisUserParams.append('tenant_user_login', createdAccUUID)
          createAcronisUserParams.append('tenant_user_password', values.password)
          createAcronisUserParams.append('tenant_user_email', values.email)
          createAcronisUserParams.append('tenant_user_firstname', values.firstname)
          createAcronisUserParams.append('tenant_user_lastname', values.lastname)
          await api.createAcronisTenant(createAcronisUserParams).then(response => {
            if (response.data === 'ok') {
              const message = 'Acronis User wurde erfolgreich erstellt'
              this.$notification.success({
                message: 'Erfolg',
                description: message,
                duration: 4
              })
            } else {
              const message = 'Das Acronis Benutzerkonto konnte nicht erstellt werden.'
              this.$notification.error({
              message: message,
              description:
                  'Das Acronis Benutzerkonto stellt alle mit Acronis in Verbindung stehenden Funktionen zur Verfügung, welche somit für das erstelle Benutzerkonto nicht verwendet werden können! Bitte löschen Sie das soeben erstellte Benutzerkonto und versuchen Sie ein Neues zu erstellen. Falls dieses Problem weiterhin besteht, kontaktieren Sie unseren Support.',
              duration: 0
            })
            }

          }).catch(error => {
            const message = 'Das Acronis Benutzerkonto konnte nicht erstellt werden.'
            this.$notification.error({
              message: message,
              description:
                  'Das Acronis Benutzerkonto stellt alle mit Acronis in Verbindung stehenden Funktionen zur Verfügung, welche somit für das erstelle Benutzerkonto nicht verwendet werden können! Bitte löschen Sie das soeben erstellte Benutzerkonto und versuchen Sie ein Neues zu erstellen. Falls dieses Problem weiterhin besteht, kontaktieren Sie unseren Support.',
              duration: 0
            })
            console.log(error)
          })
          this.$notification.success({
            message: 'Erfolg',
            description: 'Das Benutzerkonto wurde erfolgreich erstellt.',
            duration: 4
          })
          this.closeModal()
          this.loading = false
          this.listAccountsForReseller()
        }).catch(error => {
          this.closeModal()
          this.loading = false
         this.$notification.error({
           message: 'Fehler beim Erstellen des Benutzerkontos.',
           description:
               'Bei der Erstellung des Benutzerkontos ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
           duration: 0
         })
        })
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    handleUserSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRefCreateUser.value.validate().then(async () => {
        const values = toRaw(this.formCreateUser)
        // var newDomainUuid = undefined
        this.loading = true

        var params = new FormData()
        params.append('username', values.userName)
        params.append('password', values.userPassword)
        params.append('email', values.userEmail)
        params.append('firstname', values.userFirstname)
        params.append('lastname', values.userLastname)
        params.append('accountname', this.openAccount)
        params.append('domainuuid', this.openDomainUuid)

        await api.createUser(params).then(response => {
          this.closeModal()
          this.loading = false
          this.$notification.success({
            message: 'Erfolg',
            description: 'Der Benutzer wurde erfolgreich erstellt.',
            duration: 6
          })
          this.listUsersForAccounts(this.openAccount, this.openDomainUuid)
        }).catch(error => {
          console.log(error)
          this.closeModal()
          this.loading = false
          this.$notification.error({
            message: 'Fehler beim Erstellen des Benutzers.',
            description:
                'Beim Erstellen eines Benutzers ist ein Fehler aufgetreten, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
            duration: 0
          })
        })
      }).catch(error => {
        console.log(error)
        // this.loading = false
      })
    },
    handleSearch () {
      this.filteredList = this.tableBackup.filter( x => x.name.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase()))
      this.current = 1
      this.expandedKeys = []
      this.accountData = this.filteredList
      if (this.searchInput === '') {
        this.accountData = this.tableBackup
      }
    },
    handleOffersSearch () {
      this.filteredList = this.tableOffersBackup.filter( x => x.configName.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase())
        || x.customer.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase()) || (x.configNumber!= undefined && x.configNumber.toString().toLowerCase().includes(this.searchInput.toString().toLowerCase())))
      this.current = 1
      this.reloadList += 1
      this.currentConfigList = this.filteredList
      if (this.searchInput === '') {
        this.currentConfigList = this.tableOffersBackup
      }
    },
    handleSearchReset() {
      this.searchInput = ''
      this.accountData = this.tableBackup
      this.currentConfigList = this.tableOffersBackup
    },
    validateAccountName (rule, value) {
      var errorMessage = 'Der Benutzername erfüllt nicht alle Anforderungen!'
      var validated = true;
      if (this.specialChars.test(value)) {
        validated = false;
        errorMessage += "\nSonderzeichen sind nicht erlaubt!"
      }
      if (value==undefined || value.length < 4 ) {
        validated = false;
        errorMessage += "\nDer Benutzername muss mindestens 4 Zeichen lang sein!"
      }
      if (validated){
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    validateCompanyName (rule, value) {
      // const errorMessage = this.$t('message.validate.special.characters')
      var errorMessage = 'Der Firmenname erfüllt nicht alle Anforderungen!'
      var validated = true
      if (value === undefined || value.length < 4) {
        validated = false
        errorMessage += '\nDer Firmenname muss mindestens 4 Zeichen lang sein!'
      }
      if (validated) {
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    validateEmail (rule, value) {
      const errorMessage = 'Bitte geben Sie eine gültige E-Mail an. \nBeispiel: Beispiel@Email1.com\nNur Zahlen, Buchstaben und -_@. sind erlaubt!'
      if (this.emailRegex.test(value) && this.specialCharsMail.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateCustomerID(rule, value) {
      const errorMessage = 'Bitte geben Sie eine gültige Kundennummer an.\nNur Zahlen sind erlaubt!'
      if (value!=undefined && this.customerIdRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateContractNumber(rule, value){
      const errorMessage = 'Eine gültige Vertragsnummer besteht aus Zahlen, Buchstaben und -_.'
      if (this.contractNumberRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateCity(rule, value) {
      const errorMessage = 'Bitte geben Sie einen gültigen Stadtnamen an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
      if (value!=undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateStreet(rule, value) {
      const errorMessage = 'Bitte geben Sie eine gültige Adresse an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
      if (value!=undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateCountry(rule, value) {
      const errorMessage = 'Bitte geben Sie ein gültiges Land an.\nNur Zahlen, Buchstaben, Leezeichen und - sind erlaubt!'
      if (value!=undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    async validatePassword (rule, value) {
      var errorMessage = 'Ihr Passwort erfüllt nicht alle Anforderungen!'
      var validated = true;
      if (!this.passwordRegexLength.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 8 Zeichen"
      }
      if (!this.passwordRegexUpperCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Großbuchstaben"
      }
      if (!this.passwordRegexLowerCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Kleinbuchstaben"
      }
      if (!this.passwordRegexDigit.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Zahl"
      }
      if (!this.passwordRegexSpecialCharacter.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Sonderzeichen"
      }
      if (this.passwordRegexWrongSpecials.test(value)) {
        validated = false;
        errorMessage += "\nFalsche Sonderzeichen!"
      }
      if (!this.passwordRegexRepeatCharacter.test(value)) {
        validated = false;
        errorMessage += "\nKein Buchstabe darf sich nicht mehr als zweimal wiederholen!"
      }
      if(value==undefined || this.form.account==undefined || value.toLowerCase().includes(this.form.account.toLowerCase())){
        validated = false;
        errorMessage += "\nDas Passwort darf den Benutzername nicht beinhalten!"
      }
      if (validated){
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    async validateUserPassword (rule, value) {
      var errorMessage = 'Ihr Passwort erfüllt nicht alle Anforderungen!'
      var validated = true;
      if (!this.passwordRegexLength.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 8 Zeichen"
      }
      if (!this.passwordRegexUpperCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Großbuchstaben"
      }
      if (!this.passwordRegexLowerCase.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Kleinbuchstaben"
      }
      if (!this.passwordRegexDigit.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Zahl"
      }
      if (!this.passwordRegexSpecialCharacter.test(value)) {
        validated = false;
        errorMessage += "\nMindestens 1 Sonderzeichen"
      }
      if (this.passwordRegexWrongSpecials.test(value)) {
        validated = false;
        errorMessage += "\nFalsche Sonderzeichen!"
      }
      if (!this.passwordRegexRepeatCharacter.test(value)) {
        validated = false;
        errorMessage += "\nKeine Buchstabe darf sich nicht mehr als zweimal wiederholen!"
      }
      if(value==undefined || this.formCreateUser.userName==undefined || value.toLowerCase().includes(this.formCreateUser.userName.toLowerCase())){
        validated = false;
        errorMessage += "\nDas Passwort darf den Benutzername nicht beinhalten!"
      }
      if (validated){
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    validatePlz (rule, value) {
        const errorMessage = 'Die maximale Länge der Postleitzahl beträgt 8!'
        if (value!=undefined && value.length <= 8) {
          return Promise.resolve()
        }
        return Promise.reject(errorMessage)
    },
    validateConfirmPassword (rule, value) {
      if (!value || value.length === 0) {
        return Promise.resolve()
      } else if (rule.field === 'confirmpassword') {
        const messageConfirm = 'Die Passwortfelder stimmen nicht überein'
        const passwordVal = this.form.password
        if (passwordVal && passwordVal !== value) {
          return Promise.reject(messageConfirm)
        } else {
          return Promise.resolve()
        }
      } else {
        return Promise.resolve()
      }
    },
    async validateConfirmUserPassword (rule, value) {
      if (!value || value.length === 0) {
        return Promise.resolve()
      } else if (rule.field === 'userConfirmPassword') {
        const messageConfirm = 'Die Passwortfelder stimmen nicht überein'
        const passwordVal = this.formCreateUser.userPassword
        if (passwordVal && passwordVal !== value) {
          return Promise.reject(messageConfirm)
        } else {
          return Promise.resolve()
        }
      } else {
        return Promise.resolve()
      }
    },
    setCreateDomain () {
      this.form.domainid = undefined
    },
    closeModal () {
      this.showCreateAccount = false
      this.showEditAccount = false
      this.showEditUser = false
      this.showCreateUser = false
      this.deleteModalBoolean = false
      this.resourceIdent = ''
      this.resourceName = ''
      this.resourceUuid = ''
      this.configuratorId = ''
      this.userDomainId = ''
      this.accountName = ''
      this.form.domainid = undefined
      this.form.newDomainName = undefined
      this.form.domainid = undefined
      this.form.account = undefined
      this.form.city = undefined
      this.form.street = undefined
      this.form.postalcode = undefined
      this.form.country = undefined
      this.form.customerID = undefined
      this.form.username = undefined
      this.form.email = undefined
      this.form.confirmpassword = undefined
      this.form.password = undefined
      this.form.firstname = undefined
      this.form.lastname = undefined
      this.form.domain = undefined
      this.form.contractNumber = undefined
      this.createDomainBool = false
      this.formCreateUser.userName = undefined
      this.formCreateUser.userEmail = undefined
      this.formCreateUser.userPassword = undefined
      this.formCreateUser.userConfirmPassword = undefined
      this.formCreateUser.userFirstname = undefined
      this.formCreateUser.userLastname = undefined
    },
    handleTableChange () {
      this.reloadList += 1
    },
  }
}
</script>

<style scoped>

  .search-container {
    display: flex;
    justify-content: flex-end;
  }
  .offersTableContainer {
    margin-top: 15px;
  }

  @media (max-width: 1026px) {
    .customerHeaderContainer {
      width: 80%;
    }
    .customerTableContainer {
      width: 80%;
    }
  }

  @media (max-width: 700px) {
    .tableButton {
      width: 45px;
    }
  }

  @media (max-width: 768px) {
    .searchFieldInputCustomers {
      width: 300px;
    }
    .searchFieldInputOffers {
      width: 200px;
    }
  }
  @media (max-width: 643px) {
    .createAccountButtontext {
      display: none;
    }
    .searchFieldInputCustomers {
      width: 350px;
    }
    .searchFieldInputOffers {
      width: 250px;
    }
  }
  @media (max-width: 524px) {
    .customerHeaderContainer {
      width: 100%;
    }
    .customerTableContainer {
      width: 100%;
    }
    .tableButton {
      width: 45px;
    }
    .editButton {
      margin-bottom : 5px;
    }
    .createAccountButton {
      margin-left: 7px;
    }
  }
  @media (max-width: 451px) {
    .searchFieldInputCustomers {
      width: 300px;
    }
    .searchFieldInputOffers {
      width: 200px;
    }
  }
</style>
