import axios from 'axios'
import { notification } from 'ant-design-vue'


const AXIOS = axios.create({
    baseURL: `/api`
    /* timeout: 60000 */
})

AXIOS.interceptors.response.use((res) => {
    return res
    }, (error) => {
        console.log('Interceptor detected Error: ', error)
        if (error.response.status === 401) {
            document.cookie = "authenticated=false"
            notification.error({
                message: 'Sitzung abgelaufen',
                description:
                  'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
                duration: 6
            })
            window.location.href = "https://login.mitteldeutsche-it.de/"
        }
    return Promise.reject(error)
});

export default {
    // register APIs
    login (params) {
        return AXIOS.post('/login', params, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    },
    logout () {
        return AXIOS.get('/logout')
    },
    generateCode (params) {
        return AXIOS.post('/otp/generate',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    qrCode () {
        return AXIOS.get('/generateQRCode',{
           responseType: "blob"
        })
    },
    verifyOtp (params){
        return AXIOS.post('/otp/verify',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    validateOtp (params){
        return AXIOS.post('/otp/validate',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    getPartnerWithPlan (uuid) {
        return AXIOS.get('/getPartnerSubscription/'+uuid+'')
    },
    getInvoice (params) {
        return AXIOS.post('/getInvoice', params, {
            headers: { 'Content-Type': 'application/pdf' },
            responseType: "blob"
        })
    },
    sendKey (uuid) {
        return AXIOS.get('/sendCode/'+uuid)
    },
    getAccountPrices(uuid) {
        return AXIOS.get('/getAccountPrices/'+uuid)
    },
    getCalculatorAccountPrices(uuid) {
        return AXIOS.get('/calculatorAccountPrices/'+uuid)
    },
    getSalesVolume(params) {
        return AXIOS.post('/lastMonths', params)
    },
    welcome() {
        return AXIOS.get('/wellcome')
    },
    getPdf (params) {
        return AXIOS.post('/getPdf',params,{
           /*  headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }, */
                 headers: { 'Content-Type': 'application/pdf' },
                 responseType: "blob"
        })
    },
    getAccountByCookie() {
        return AXIOS.get('/getAccountByCookie')
    },
    checkActiveInvoices (params) {
        return AXIOS.post('/checkActiveInvoices', params, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    },
    // setRedirectCookies (param) {
    //     return AXIOS.post('/setCookieRedirect',param,{
    //         headers: { 'Content-Type': 'multipart/form-data' }
    //     })
    // },
    // getLoginCookie() {
    //     return AXIOS.get('/getLoginCookies')
    // },
    setCookieLocally() {
        return AXIOS.get('/setCookiesLocally')
    },
    checkCookieLocally() {
        return AXIOS.get('/checkCookie')
    },
    listAccounts (accountuuid){
        return AXIOS.get('/listAccounts/'+accountuuid)
    },
    createAccount (params){
        return AXIOS.post('/createAccount',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    deleteAccount (accountuuid){
        return AXIOS.get('/deleteAccount/'+accountuuid)
    },
    updateAccount (params){
        return AXIOS.post('/updateAccount',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    listUsers (params){
        return AXIOS.post('/listUsers',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    createUser (params){
        return AXIOS.post('/createUser',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    deleteUser (useruuid){
        return AXIOS.get('/deleteUser/'+useruuid)
    },
    updateUser (params){
        return AXIOS.post('/updateUser',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    listDomains (accountuuid){
        return AXIOS.get('/listDomains/'+accountuuid)
    },
    listChildrenDomains (accountuuid){
        return AXIOS.get('/listChildrenDomains/'+accountuuid)
    },
    disableOrLockAccount (params){
        return AXIOS.post('/lockOrDisableAccount',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    disableUser (useruuid){
        return AXIOS.get('/disableUser/'+useruuid)
    },
    getMitAccountData (params){
        return AXIOS.post('/getMitAccountData',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    changeUserPassword (params){
        return AXIOS.post('/changeUserPassword',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    createDomain (params){
        return AXIOS.post('/createDomain',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    createAcronisTenant (params){
        return AXIOS.post('/createAcronisTenant',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    setCustomerLogo (params){
        return AXIOS.post('/setCustomerLogo',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    getCustomerLogo (params){
        return AXIOS.post('/getCustomerLogo',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    deleteCustomerLogo (params){
        return AXIOS.post('/deleteCustomerLogo',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    saveConfigurator (params){
        return AXIOS.post('/saveConfigurator',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    listConfigurator (params){
        return AXIOS.post('/listConfigurator',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    deleteConfigurator (params){
        return AXIOS.post('/deleteConfigurator',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    updateConfigurator (params){
        return AXIOS.post('/updateConfigurator',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    updateOfferStatus (params){
        return AXIOS.post('/changeOfferStatus',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    listResellerPrices (params){
        return AXIOS.get('/listResellerPrices',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },
    setVkPrice (params){
        return AXIOS.post('/setVkPrice',params,{
            headers: { 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        })
    },

}
