<template>
  <div class="kdz-config-form" v-if="isSidebarOpen">
    <div class="kdz-sidebar-head">
      <span>{{ selectedServer.name }}</span>
        <a-button
          @click="cancelConf=true"
          class="exit-button"
          type="primary"
        >
          X
        </a-button>
    </div>
    <!-- Config for Resources in Sidebar for iaas and desktop/cloudserver -->
    <div class="settings-container" v-if="selectedServer.type === 'iaas' || selectedServer.type === 'cloudServer'">
      <a-input
        id="cloudServerName"
        :class="validateServerName ? 'serverNameValidation' : ''"
        type="text"
        v-model:value="serverName"
        :maxLength="28"
        placeholder="Servername"
        style="margin-bottom: 15px;"
      ></a-input>
      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <ConfigFormField
        v-for="(field, j) of numberFields"
        :key="j"
        :options="field"
        :isResource="true"
        :requireCpu="requireCpu"
        :requireRam="requireRam"
        :requireStorage="requireStorage"
        :submitButtonClicked="submitButtonClicked"
        :serverType="selectedServer.type"

      />
      <ConfigOsList 
      :serverType="selectedServer.type"/>
      <div>
        <div>
          <a-button
            type="primary"
            @click="createServer"
            shape="round"
            style="text-align: top;"
          >
            <img src='../../assets/click-arrow.svg' width="19"/>
            <strong style="color: #fff"> Erstellen</strong>
          </a-button>
          <br/>
          <span v-if="((requireCpu || requireRam || requireStorage) && submitButtonClicked)" style="color: red; font-weight: bold;">Mindestanfordungen zum Erstellen eines Cloud-Servers: CPU, RAM und Storage</span>
        </div>
      </div>
    </div>
    <!-- Config for Resources in Sidebar for Collaboration & Cloud Speicher -->
    <div class="settings-container" v-if="selectedServer.type === 'wpCloud'">
      <a-input
        id="collaborationServerName"
        :class="validateServerName ? 'serverNameValidation' : ''"
        type="text"
        v-model:value="serverName"
        :maxLength="28"
        placeholder="Servername"
        style="margin-bottom: 15px;"
      ></a-input>
      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <div style="display: flex; flex-direction: row; justify-content: center;">
        <a-popover :title="'Standard-Einstellung:'">
          <template #content>
            <span>{{'Die Standard-Einstellungen sind nicht änderbar und'}}</span><br>
            <span>{{'werden  bei jedem Cloud-Speicher mit berechnet.'}}</span>
          </template>
          <ExclamationCircleOutlined />
        </a-popover>
        <div style="margin-left: 5px; font-weight: bold;">Standard-Einstellungen:</div>
      </div>
      
      <ConfigFormField
        v-for="(field, j) of numberFields"
        :key="j"
        :options="field"
        :isResource="true"
        :submitButtonClicked="submitButtonClicked"
        :serverType="selectedServer.type"
      />
      <ConfigOsList 
      :serverType="selectedServer.type"/>
      <div>
        <div>
          <a-button
            type="primary"
            @click="createServer"
            shape="round"
            style="text-align: top;"
          >
            <img src='../../assets/click-arrow.svg' width="19"/>
            <strong style="color: #fff"> Erstellen</strong>
          </a-button>
          <br/>
          <span v-if="((requireCpu || requireRam || requireStorage) && submitButtonClicked)" style="color: red; font-weight: bold;">Mindestanfordungen zum Erstellen eines Cloud-Servers: CPU, RAM und Storage</span>
        </div>
      </div>
    </div>
    <!-- Config for Resources in Sidebar for Cyber Cloud Endpoint (Backup & Security) -->
    <div class="settings-container" v-if="selectedServer.type === 'cybercloudendpoint'">
      <a-input
        id="endpointServerName"
       :class="validateServerName ? 'serverNameValidation' : ''"
        type="text"
        v-model:value="serverName"
        :maxLength="28"
        placeholder="Servername"
        style="margin-bottom: 15px;"
      ></a-input>
      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <div style="display: flex; flex-direction: row; justify-content: center;">
        <a-popover :title="'Endpoint-Auswahl:'">
          <template #content>
            <span>{{'Sie haben die Möglichkeit einen einzelnen Endpoint oder direkt'}}</span><br>
            <span>{{'eine gesamte Gruppe aus mehreren Endpoints zu konfigurieren.'}}</span>
          </template>
          <ExclamationCircleOutlined />
        </a-popover>
        <div style="margin-left: 5px; font-weight: bold;">Endpoint-Auswahl:</div>
      </div>
      
      <CyberCloudFormField/>
      <CyberCloudOsList 
      :serverType="selectedServer.type"/>
      <div>
        <div>
          <a-button
            type="primary"
            @click="createServer"
            shape="round"
            style="text-align: top;"
          >
            <img src='../../assets/click-arrow.svg' width="19"/>
            <strong style="color: #fff"> Erstellen</strong>
          </a-button>
          <br/>
        </div>
      </div>
    </div>
    <div class="settings-container" v-if="selectedServer.type === 'container'">
      <a-input
        id="containerServerName"
        :class="validateServerName ? 'serverNameValidation' : ''"
        type="text"
        v-model:value="serverName"
        :maxLength="28"
        placeholder="Servername"
        style="margin-bottom: 15px;"
      ></a-input>
      <span v-if="validateServerName && submitButtonClicked" style="color: red; font-weight: bold;">Ein gültiger Name besteht aus Zahlen, Buchstaben und &_. und besteht aus maximal 28 Zeichen!</span>
      <ContainerFormField
        :requireReplica="requireReplica"
        :requireCpu="requireCpu"
        :requireRam="requireRam"
        :requireStorage="requireStorage"
        :submitButtonClicked="submitButtonClicked"
        />
      <div>
        <div>
          <a-button
            type="primary"
            @click="createServer"
            shape="round"
            style="text-align: top;"
          >
            <img src='../../assets/click-arrow.svg' width="19"/>
            <strong style="color: #fff"> Erstellen</strong>
          </a-button>
          <br/>
          <span v-if="((requireReplica || requireCpu || requireRam || requireStorage) && submitButtonClicked)" style="color: red; font-weight: bold;">Mindestanfordungen zum Erstellen eines Containers: Replicas, CPU, RAM und Storage</span>
        </div>
      </div>
    </div>
    
    <a-modal
      :title="'Konfiguration abbrechen?'"
      v-model:open="cancelConf"
      :closable="true"
      @cancel="cancelConf = false"
      @ok="closeSidebar()"
      okText="Ok"
      cancelText="Abbrechen"
      centered>
      Wollen Sie die Konfiguration wirklich abbrechen?
    </a-modal>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import ConfigFormField from "./ConfigFormField.vue";
import CyberCloudFormField from "./CyberCloudFormField.vue";
import ConfigOsList from "./ConfigOsList.vue";
import ContainerFormField from "./ContainerFormField.vue"
import CyberCloudOsList from "./CyberCloudOsList.vue";
import { imageUrl, dialog } from "@/mixins/mixins.js";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { toRaw } from 'vue'


export default {
  name: "CloudConfigFormular",
  components: {
    ConfigFormField,
    ConfigOsList,
    ExclamationCircleOutlined,
    CyberCloudFormField,
    CyberCloudOsList,
    ContainerFormField
  },
  mixins: [imageUrl, dialog],
  data() {
    return {
      cancelConf: false,
      submitButtonClicked: false,
      serverNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.& ]*$/
    };
  },
  computed: {
    ...mapGetters(["selectedServer", "isSidebarOpen"]),
    serverName: {
      get() {
        return this.selectedServer.name;
      },
      set(value) {
         let server = this.selectedServer;
        server.name = value;
        /* this.setSelectedServer(server);  */
      },
    },
    requireReplica() {
      if (this.selectedServer.type == 'container') {
        if (parseInt(this.selectedServer.configuration.Replicas) <= 0) {
          return true
        } 
      }
      return false
    },
    requireCpu() {
      if (this.selectedServer.type == 'container') {
        if (parseInt(this.selectedServer.configuration.container_cpu) <= 0) {
          return true
        }
      } else {
        if (parseInt(this.selectedServer.configuration.vCPU) <= 0) {
          return true
        } 
      }
      return false
    },
    requireRam() {
      if (this.selectedServer.type == 'container') {
        if (parseInt(this.selectedServer.configuration.container_memory) <= 0 || this.selectedServer.configuration.container_memory === undefined) {
          return true
        }
      } else {
        if (parseInt(this.selectedServer.configuration.MEMORY) <= 0) {
          return true
        } 
      }
      return false
    },
    requireStorage() {
      if (this.selectedServer.type == 'container') {
        if (parseInt(this.selectedServer.configuration.container_storage) <= 0 || this.selectedServer.configuration.container_storage === undefined) {
          return true
        }
      } else {
        if (parseInt(this.selectedServer.configuration.SSD) <= 0 && parseInt(this.selectedServer.configuration.HDD) <= 0) {
          return true
        } 
      }
      return false
    },
    validateServerName() {
      if (!this.serverNameRegex.test(this.selectedServer.name) || this.selectedServer.name == ""){
        return true
      }
      return false
    },
    formTitle() {
      return this.$route.name === "collaboration"
        ? "Neuer Speicher"
        : this.$route.name === "cybercloudendpoint"
        ? "Neues Backup"
        : "Neuer Server";
    },
    numberFields() {
      const original = this.$store.getters.getPriceList
      var clone = new Map(original)
      for (var [key, value] of clone.entries()) {
        if (value.isLicense || key.includes('container_') || key.includes('k8s_')) {
          clone.delete(key)
        }
      }
      // sort the clone
      var sortedClone = new Map([...clone.entries()].sort((a, b) => toRaw(a[1]).order - toRaw(b[1]).order))
      return sortedClone
    },
  },
  methods: {
    ...mapMutations([
      "addServer",
      "resetServer",
      "setSelectedServer",
      "setSidebarVisibility",
    ]),

    createServer() {
      this.submitButtonClicked = true
      if (this.requireCpu || this.requireRam ||this.requireStorage || this.requireReplica) {
        if (this.selectedServer.type === 'iaas' || this.selectedServer.type === 'cloudServer') {
          document.getElementById('isResourceInputContainer').scrollIntoView({ behavior: "smooth" });
        }
        return
      }
      if (this.validateServerName) {
        if (this.selectedServer.type === 'iaas' || this.selectedServer.type === 'cloudServer') {
          document.getElementById('cloudServerName').scrollIntoView({ behavior: "smooth" })
        }
        if (this.selectedServer.type === 'wpCloud') {
          document.getElementById('collaborationServerName').scrollIntoView({ behavior: "smooth" })
        }
        if (this.selectedServer.type === 'container') {
          document.getElementById('containerServerName').scrollIntoView({ behavior: "smooth" })
        }
        if (this.selectedServer.type === 'cybercloudendpoint') {
          document.getElementById('endpointServerName').scrollIntoView({ behavior: "smooth" })
        } 
        return
      }
      this.submitButtonClicked = false
      this.setSidebarVisibility(false);
      this.addServer();
      this.setSelectedServer({});
    },
    closeSidebar() {
        this.setSidebarVisibility(false);
        this.resetServer();
        this.cancelConf = false
    },
  },
};
</script>

<style lang="scss">

.kdz-config-form {
  width: 100%;
  height: auto;

  input[type="number"] {
    padding: 0.2rem 0.5rem;
  }

}

.settings-container {
  position: relative;

  margin-bottom: 5rem;
}

.save-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 1rem 2rem;
  margin: 0;
  z-index: 5;
}
.serverNameValidation {
  border-color: red;
  border-width: 2px;
}

@media (min-width: 768px) {
  .kdz-config-form {
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
    height: 700px;
  }

  .save-button {
    margin: 1rem;
    position: relative;
    bottom: auto;
    top: auto;
    left: auto;
    right: auto;
    padding: 0;
  }

  .settings-container {
    max-height: 75%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
  }
}
</style>
