<template>
  <div id="productSelectionContainer">
    <div class="kdz-cloud-select-title">
      {{ title }}
    </div>
    <div>
      <router-link
      :to="{ path: linkName }"
      :class="[
        'row align-items-center',
        {
          'router-link-exact-active router-link-active':
            $route.name === linkName,
        },
      ]"
      >
      <div style="display: flex; align-items: center; justify-content: center">
        <span class="kdz-cloud-select-button clickable" :id="myID"></span>
        <img class="kdz-cloud-select-img" :src="getImgUrl(src)" />
      </div>
      </router-link>
  </div>
</div>
</template>

<script>
export default {
  name: "CloudSelectItem",
  props: {
    src: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    linkName: {
      type: String,
      default() {
        return "/";
      }
    },
    myID: {
      type: Number,
      required: true,
    },
  },
  updated () {
    this.checkCheckbox()
  },
  methods: {
    getImgUrl(pic) {
      return require('../assets/'+pic)
    },
    checkCheckbox() {
      if (this.$route.path === '/container') {
        document.getElementById(0).style.backgroundColor = "#3d7caf";
        document.getElementById(1).style.backgroundColor = "transparent";
        document.getElementById(2).style.backgroundColor = "transparent";
        document.getElementById(3).style.backgroundColor = "transparent";
        document.getElementById(4).style.backgroundColor = "transparent";
      }
      if (this.$route.path === '/cloudServer') {
        document.getElementById(1).style.backgroundColor = "#3d7caf";
        document.getElementById(0).style.backgroundColor = "transparent";
        document.getElementById(2).style.backgroundColor = "transparent";
        document.getElementById(3).style.backgroundColor = "transparent";
        document.getElementById(4).style.backgroundColor = "transparent";
      }
      if (this.$route.path === '/iaas') {
        document.getElementById(2).style.backgroundColor = "#3d7caf";
        document.getElementById(1).style.backgroundColor = "transparent";
        document.getElementById(3).style.backgroundColor = "transparent";
        document.getElementById(0).style.backgroundColor = "transparent";
        document.getElementById(4).style.backgroundColor = "transparent";
      }
      if (this.$route.path === '/cybercloudendpoint') {
        document.getElementById(4).style.backgroundColor = "#3d7caf";
        document.getElementById(0).style.backgroundColor = "transparent";
        document.getElementById(1).style.backgroundColor = "transparent";
        document.getElementById(3).style.backgroundColor = "transparent";
        document.getElementById(2).style.backgroundColor = "transparent";
      }
      if (this.$route.path === '/collaboration') {
        document.getElementById(3).style.backgroundColor = "#3d7caf";
        document.getElementById(1).style.backgroundColor = "transparent";
        document.getElementById(2).style.backgroundColor = "transparent";
        document.getElementById(0).style.backgroundColor = "transparent";
        document.getElementById(4).style.backgroundColor = "transparent";
      }
      if (this.$route.path === '/prices'){
        document.getElementById(0).style.backgroundColor = "transparent";
        document.getElementById(1).style.backgroundColor = "transparent";
        document.getElementById(2).style.backgroundColor = "transparent";
        document.getElementById(3).style.backgroundColor = "transparent";
        document.getElementById(4).style.backgroundColor = "transparent";
      }
      
    }
  }
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;

  &:hover {
    color: #D02C2A;
  }
}
.kdz-cloud-select {
  &-button {
    width: 25px;
    height: 25px;
    display: block;
    border: 1px solid #3d7caf;
    border-radius: 5px;
    margin-right: 0.5rem;

    &:hover {
      background-color: #3d7caf;
    }
  }

  &-img {
    width: 80px;
  }

  &-title {
    text-align: center;
    height: 35px;
  }
}

@media (min-width: 768px) {
  .kdz-cloud-select {
    &-button {
      margin-right: 1rem;
    }
  }
}

@media (min-width: 992px) {
  .kdz-cloud-select {
    &-button {
      margin-right: 1.5rem;
    }

    &-img {
      width: auto;
      min-width: 120px;
    }
    &-title {
    padding: 0 5px;
  }
  }
}
@media ( max-width: 790px) {
  .cloudSelectItem{
    margin-right: 0;
    width: 100%;
  }
}
</style>
