<template>
  <div
    :class="[
      'kdz-sidebar',
      { open: isSidebarOpen },
      (discountType === 'Holz' ? 'woodPartner' : (discountType === 'Bronze' ? 'bronzePartner' : (discountType === 'Silber' ? 'silverPartner' : (discountType === 'Gold' ? 'goldPartner' : (discountType === 'Platin' ? 'platinumPartner' : 'standardPartner'))))),
    ]"
  >
    <div class="configFormularPosition"><ConfigFormular /></div>
  </div>
</template>

<script>
import ConfigFormular from "@/components/forms/ConfigFormular.vue";
import { mapMutations, mapGetters } from "vuex";
import { dialog } from "@/mixins/mixins.js";

export default {
  name: "Sidebar",
  components: {
    ConfigFormular,
  },
  mixins: [dialog],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isSidebarOpen", "discountType"]),
  },
  methods: {
    ...mapMutations(["resetServer", "setSidebarVisibility"]),
    closeSidebar() {
      const callback = (res) => {
        if (res) {
          this.setSidebarVisibility(false);
          this.resetServer();
        }
      };
      this.confirmDialog(
        "Konfiguration abbrechen?",
        "Wollen Sie die Konfiguration wirklich abbrechen?",
        callback
      );
    },
  },
};
</script>

<style scoped>
.kdz-sidebar {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 0;
  bottom: 0;
  transition: 0.8s all ease-in-out;
  border-right: 1px solid #ececec;
  color: #fff;
  z-index: 20;
  font-size: 15px;
}
.configFormularPosition {
  display: flex; 
  position: relative; 
  top: 100px;
}

.open {
  left: 0;
  width: 100%;
}

@media (min-width: 768px) {
  .kdz-sidebar {
    position: absolute;
    height: calc( 100vh - (50px));
    right: -240px;
    z-index: 5;
  }
  .open {
    left: 0;
    width: 320px;
  }
}

@media (max-width: 767px) {
  .kdz-sidebar {
    position: absolute;
    overflow: auto;
    top: 95px;
    height: calc( 89.5vh - (50px));
    right: -240px;
  }
  .open {
    left: 0;
    width: calc( 100% - (1px));
  }
  .configFormularPosition {
    display: flex; 
    position: relative; 
    top: 5px;
  }
}
</style>
