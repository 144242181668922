<template>
    <div style="display: flex; flex-direction: column;">
        <div class="inputContainer" id ="isResourceInputContainer">
            <div style="display: flex; flex-direction: row;">
                <span class="textField" style="margin-right: 3px;">Replicas</span>
                <a-tooltip placement="right">
                    <template #title>{{ 'Wählen Sie die gewünschte Anzahl an Replicas. Die Menge der CPU Kerne und GB an RAM werden mit der Anzahl der Replicas multipliziert.' }}</template>
                    <ExclamationCircleOutlined />
                </a-tooltip>
            </div>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('Replica') && submitButtonClicked) ? true : false }]" :min="1" :max="5" v-model:value="Replicas"></a-input-number>
        </div>
        <div class="inputContainer">
            <span class="textField">CPU Kerne</span>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('CPU') && submitButtonClicked) ? true : false }]" :min="1" :max="999" v-model:value="CPU"></a-input-number>
        </div>
        <div class="inputContainer">
            <span class="textField">RAM-Limit in GB</span>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('RAM') && submitButtonClicked) ? true : false }]" :min="1" :max="9999" v-model:value="RAM"></a-input-number>
        </div>
        <div class="inputContainer">
            <span class="textField">Storage-Limit in GB</span>
            <a-input-number :class="['inputField', { inputFieldRequired: (setRequireField('Storage') && submitButtonClicked) ? true : false }]" :min="1" :max="99999" v-model:value="Disk"></a-input-number>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
    components: {
        ExclamationCircleOutlined
    },
    props: {
        requireReplica: {
            type: Boolean,
            required: false
        },
        requireCpu: {
            type: Boolean,
            required: false
        },
        requireRam: {
            type: Boolean,
            required: false
        },
        requireStorage: {
            type: Boolean,
            required: false
        },
        submitButtonClicked: {
            type: Boolean,
            required: false
        },
    },
    computed: {
      ...mapGetters(["selectedServer"]),
      Replicas: {
        get() {
            if (this.selectedServer.configuration["Replicas"] === undefined) {
                this.updateConfig("Replicas", 1);
                return 1
            }
            return this.selectedServer.configuration["Replicas"]
        },
        set(value) {
            if (value > 5) {
                value = 5
            } else if (value < 1) {
                value = 1
            }
            this.updateConfig("Replicas", value);
        }
      },
      CPU: {
        get() {
            if (this.selectedServer.configuration["container_cpu"] === undefined || this.selectedServer.configuration["container_cpu"] < 1) {
                this.updateConfig("container_cpu", 1);
                return 1
            }
            return this.selectedServer.configuration["container_cpu"]
        },
        set(value) {
            if (value > 999) {
                value = 999
            } else if (value < 1) {
                value = 1
            }
            this.updateConfig("container_cpu", value);
        }
      },
      RAM: {
        get() {
            if (this.selectedServer.configuration["container_memory"] === undefined || this.selectedServer.configuration["container_memory"] < 1) {
                this.updateConfig("container_memory", 1);
                return 1
            }
            return this.selectedServer.configuration["container_memory"]
        },
        set(value) {
            if (value > 9999) {
                this.updateConfig("container_memory", 9999);
            } else if (value < 1) {
                this.updateConfig("container_memory", 1);
            } else {
                this.updateConfig("container_memory", value);
            }
        }
      },
      Disk: {
        get() {
            if (this.selectedServer.configuration["container_storage"] === undefined || this.selectedServer.configuration["container_storage"] < 1) {
                this.updateConfig("container_storage", 1);
                return 1
            }
            return this.selectedServer.configuration["container_storage"]
        },
        set(value) {
            if (value > 99999) {
                this.updateConfig("container_storage", 99999);
            } else if (value < 1) {
                this.updateConfig("container_storage", 1);
            } else {
                this.updateConfig("container_storage", value);
            }
        }
      },
    },
    methods: {
      ...mapMutations(["setSelectedConfiguration"]),
      updateConfig(attribute, value) {
        let server = this.selectedServer;
        server.configuration[attribute] = value;
        this.setSelectedConfiguration(server.configuration);
      },
      setRequireField(input) {
      if (input === 'CPU') {
        return this.requireCpu
      } else if (input === 'RAM') {
        return this.requireRam
      } else if (input === 'Storage') {
        return this.requireStorage
      } else if (input === 'Replica') {
        return this.requireReplica
      }
    },
    },
}
</script>

<style>
.inputField {
    width: 60px;
}
.textField {
    display: flex;
    align-items: center;
}
.inputContainer {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    padding: 2px;
}
.inputFieldRequired {
  width: 60px;
  margin-top: 5px;
  border-color: red;
  border-width: 2px;
}
</style>