<template>
    <span>
      {{ title }}
      <a-tooltip v-if="tooltip" :title="tooltip">
        <InfoCircleOutlined :style="{color: myColor}"/>
      </a-tooltip>
    </span>
  </template>
  
  <script>
  import { InfoCircleOutlined } from '@ant-design/icons-vue';
  
  export default {
    name: 'TooltipLabel',
    components: {
        InfoCircleOutlined
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      tooltip: {
        type: String,
        default: null
      },
      myColor: {
        required: false,
        type: String,
        default: 'black'
      }
      /* tooltipPlacement: {
        type: String,
        default: 'top'
      } */
    }
  }
  </script>
  
  <style scoped>
  </style>