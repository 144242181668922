<template>
  <div
    fluid
    :class="[
      'kdz-costs text-white',
      ,
      (discountType === 'Holz' ? 'woodPartner' : (discountType === 'Bronze' ? 'bronzePartner' : (discountType === 'Silber' ? 'silverPartner' : (discountType === 'Gold' ? 'goldPartner' : (discountType === 'Platin' ? 'platinumPartner' : 'standardPartner'))))),
    ]"
    style="text-align: right;"
  >
  <a-tabs v-model:activeKey="activeKey" type="card" class="costoverview" :tabBarGutter="0">
    <a-tab-pane :key="'1'">
      <template #tab>
        <a-popover>
          <span>Einkaufspreis</span>
          <template #content>
          <span>{{ 'Kalkulation' }}</span>
        </template>      
        </a-popover>       
      </template>
      <div  style="font-size: 16px; text-align: left; color:white;" v-if="$store.getters.getImportedConfigName">
        <div style="font-weight: bold">{{ 'Angebot in Bearbeitung: ' }}</div>
        <div>Angebot: {{ $store.getters.getImportedConfigName }}</div>
        <div v-if="$store.getters.getImportedConfigNumber">Angebotsnummer: {{ $store.getters.getImportedConfigNumber }}</div>
        <div v-else>Angebotsnummer: -</div>
      </div>
      <div class="kdz-sidebar-head px-2 py-4">
        <div>Kosten&shy;übersicht</div>
        <div style="display: flex; align-self: top; height: 100%;">
          <a-button
          
            @click="cancelConf=true"
            class="exit-button"
            size="small"
            v-if="!isRoot"
            type="primary"
          >
            X
          </a-button>
        </div>
      </div>
      <div class="kdz-cost-list">
        <template v-for="(pos, index) in bill" :key="index + pos.serverName">
          <div>
            <strong v-html="pos.serverName"></strong>
          </div>
          <template v-for="(invItem, ind) in pos.bill">
            <div
              style="display: flex; justify-content: space-between;"
              :key="ind + '_' + index + pos.serverName"
              v-if="invItem.type !== 'network'"
              :class="[{ 'kdz-last-element': ind === pos.bill.length - 1 }, 'kdz-cost-border']"
            >
              <a-popover placement="left" :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth>790" >
                <template #content>
                  <span>{{invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')')}}</span>
                </template>
                <span class="hoverLabel">{{ invItem.label }}</span>
              </a-popover>
              <a-popover placement="top" :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth<=790" >
                <template #content>
                  <span>{{invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')')}}</span>
                </template>
                <span class="hoverLabel">{{ invItem.label }}</span>
              </a-popover>

              <span style="width: 30%; display: flex; justify-content: right;" cols="5" class="text-right" align-self="center">
                <i>{{ invItem.price.toString().replace('.' , ',') }}</i> &euro;&nbsp;{{ '(' + invItem.discount + '%)' }}
              </span>
            </div>
          </template>
        </template>
      </div>

      <div class="kdz-cost-sum text-right">
        <span>Partner Status</span>
        <a-select
         id="houseInput"
          v-model:value="houseSelect"
          style="width: 200px; margin-bottom: 10px;"
        >
          <a-select-option v-for="option in houseOptions" :key="option.partnerValue">
            {{ option.text}}
          </a-select-option>
        </a-select>
        <span> Gesamtkosten / Monat<br/></span>
        <span> alle Preise exkl. Mwst.<br/></span>
        <span>
          <strong>Summe: {{ summary[1].toString().replace("." , ",") }} € </strong> <br />
          - {{ partnerDiscount }} % Rabatt* <tooltip-label :myColor="'white'" :tooltip="'Partner-Rabatte beziehen sich auf folgende Resourcen: vCPU, RAM, IP-Adressen, GPU, TSPlus, SSD, HDD und Next-Generation-Firewall.'"/>
        </span>
        <a-col class="big">{{ summary[0].toString().replace("." , ",") }} €</a-col>
        <template v-if="isCloudOrIaas">
          <br/>
          <span>
            Kosten pro Stunde <br />
            (mntl. 730h &#8960;) <span v-if="discount > 0 && houseSelect">mit Rabatt</span>
          </span>
          <a-col class="big"> {{ costPerHour.toString().replace('.' , ',') }} €</a-col>
        </template>
      </div>
      <!-- <div style="display: flex; justify-content: center; margin-top: 20px; flex-direction: column; text-align: left; padding: 0 10px 0 10px;">
        <div>
          <a-form
            :ref="formRef"
            :model="form"
            :rules="rules"
            @finish="submit">
            <a-form-item ref="checkbox" name="checkbox">
              <a-checkbox
                id="checkbox-1"
                v-model:checked="form.checkbox"
              >
              </a-checkbox>
              <tooltip-label :myColor="'white'" :tooltip="' Die Datenschutzhinweise habe ich gelesen. Mit Angabe Ihrer Daten stimmen Sie zu, dass wir Ihnen Ihr konfiguriertes Angebot zuschicken und Sie dementsprechend beraten können. Es gelten unsere Allgemeinen Vertragsbedingungen, welche wir Ihnen gemeinsam mit dem unverbindlichen Angebot per Mail zuschicken.'"/>
              <span style="color: white;"> Wir verarbeiten die Daten entsprechend unserer </span>
                <a
                  href="https://mitteldeutsche-it.de/datenschutz/"
                  style="color: white;"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Datenschutz&shy;bestimmungen</a
                >
            </a-form-item>
          </a-form>
        </div>
      </div> -->
    </a-tab-pane>
    <a-tab-pane :key="'2'" style="color: white;">
      <template #tab>
        <a-popover>
          <span>Verkaufspreis</span>
          <template #content>
          <span>{{ 'Kundenangebot' }}</span>
        </template>      
        </a-popover>       
      </template>
      <div  style="font-size: 16px; text-align: left;" v-if="$store.getters.getImportedConfigName">
        <div style="font-weight: bold">{{ 'Angebot in Bearbeitung: ' }}</div>
        <div>Angebot: {{ $store.getters.getImportedConfigName }}</div>
        <div v-if="$store.getters.getImportedConfigNumber">Angebotsnummer: {{ $store.getters.getImportedConfigNumber }}</div>
        <div v-else>Angebotsnummer: -</div>
      </div>
      <div :key="updateIndex" class="kdz-sidebar-head px-2 py-4">
        <div>Kosten&shy;übersicht</div>
        <div style="display: flex; align-self: top; height: 100%;">
          <a-button
          
            @click="cancelConf=true"
            class="exit-button"
            size="small"
            v-if="!isRoot"
            type="primary"
          >
            X
          </a-button>
        </div>
      </div>
      <div class="kdz-cost-list">
        <template v-for="(pos, index) in bill" :key="index + pos.serverName">
          <div>
            <strong v-html="pos.serverName"></strong>
          </div>
          <template v-for="(invItem, ind) in pos.bill">
            <div
              style="justify-content: space-between;"
              :key="ind + '_' + index + pos.serverName"
              v-if="invItem.type !== 'network'"
              :class="[{ 'kdz-last-element': ind === pos.bill.length - 1 }, 'kdz-cost-border']"
            >
              <a-popover placement="left" :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth>790" >
                <template #content>
                  <span>{{invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')')}}</span>
                </template>
                <span class="hoverLabel">{{ invItem.label }}</span>
              </a-popover>
              <a-popover placement="top" :title="invItem.hoverLabel + ' = ' + invItem.singlePrice.toString().replace('.' , ',') + '€ '" v-if="this.windowWidth<=790" >
                <template #content>
                  <span>{{invItem.UsageForm + 'Netto' + (invItem.discount > 0 ? ', inkl. ' + invItem.discount + '% Rabatt)' : ')')}}</span>
                </template>
                <span class="hoverLabel">{{ invItem.label }}</span>
              </a-popover>

              <span style="width: 30%; display: inline-flex; justify-content: right; float: right" cols="5" class="text-right" align-self="center">
                <i>{{ ((typeof invItem.originalVkPrice === 'string' || invItem.originalVkPrice instanceof String) ? invItem.originalVkPrice : invItem.originalVkPrice.toFixed(2)).toString().replace("." , ",") }}</i> &euro;&nbsp;
              </span>
              <span v-if="invItem.resourceId === undefined" style="width: 30%; display: flex;" align-self="center">
                <i>{{ invItem.vkSinglePrice.toString().replace('.' , ',') }}</i> &euro;&nbsp;
              </span>
              <a-input-number v-else style="width: 50%;" :min="0" decimalSeparator="," :controls="false" v-model:value="invItem.vkSinglePrice" @change="handleEnter($event, bill, invItem.resourceId)" placeholder="Kein Preis verfügbar"  />
            </div>
          </template>
        </template>
      </div>
      <div :key="updateSum" class="kdz-cost-sum text-right">
        <span> Gesamtkosten Netto VK/ Monat<br/></span>
        <strong>Summe: {{  vkPriceSummary.toString().replace("." , ",") }} € </strong> <br />
        <span> Gesamtkosten Netto EK/ Monat<br/></span>
        <strong>Summe: {{ summary[0].toString().replace("." , ",") }} €</strong> <br />
        <strong>Netto Ertrag: {{ getDifferenz(vkPriceSummary, summary[0]).toString() }} €</strong> <br/>
      </div>
      <!-- <div style="display: flex; justify-content: center; margin-top: 20px; flex-direction: column; text-align: left; padding: 0 10px 0 10px;">
        <div>
          <a-form
            :ref="formRef"
            :model="form"
            :rules="rules"
            @finish="submit">
            <a-form-item ref="checkbox" name="checkbox">
              <a-checkbox
                id="checkbox-1"
                v-model:checked="form.checkbox"
              >
              </a-checkbox>
              <tooltip-label :myColor="'white'" :tooltip="' Die Datenschutzhinweise habe ich gelesen. Mit Angabe Ihrer Daten stimmen Sie zu, dass wir Ihnen Ihr konfiguriertes Angebot zuschicken und Sie dementsprechend beraten können. Es gelten unsere Allgemeinen Vertragsbedingungen, welche wir Ihnen gemeinsam mit dem unverbindlichen Angebot per Mail zuschicken.'"/>
              <span style="color: white;"> Wir verarbeiten die Daten entsprechend unserer </span>
                <a
                  href="https://mitteldeutsche-it.de/datenschutz/"
                  style="color: white;"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Datenschutz&shy;bestimmungen</a
                >
            </a-form-item>
          </a-form>
        </div>
      </div> -->
    </a-tab-pane> 
  </a-tabs>
  <div style="display: flex;">
    <div>
      <a-popover v-if="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')" placement="top">
        <template #content>
          <span>{{ 'Schließen Sie Ihre aktuelle Konfiguration ab. Zum Speichern eines Angebots muss min. ein Server konfiguriert sein!' }}</span>
        </template>
        <a-button id="saveConfBtn1" type="primary" size="large" style="width: 270px; margin-bottom: 10px; margin-right: 10px;" @click="saveConfig()" :disabled="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot speichern</b></a-button>
      </a-popover>
      <a-button id="saveConfBtn2" v-else style="margin-bottom:5px; width: 270px; margin-right: 10px;" type="primary" size="large" @click="saveConfig()" :disabled="isSidebarOpen || bill.length === 0 || (bill.length === 1 && $route.path === '/iaas')"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot speichern</b></a-button>
      <a-popover v-if="!configButton" placement="left">
        <template #content>
          <span>{{ 'Bitte speichern Sie das Angebot ab, bevor es herunterladen.' }}</span>
        </template>
        <a-button id="downloadOfferBtn2" style="margin-bottom:5px; width: 270px; margin-right: 10px;" type="primary" size="large" @click="getOffer()" :disabled="!configButton"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot herunterladen</b></a-button>
      </a-popover>
      <a-button v-else id="downloadOfferBtn" style="margin-bottom:5px; width: 270px; margin-right: 10px;" type="primary" size="large" @click="getOffer()" :disabled="!configButton"><span style="margin-right: 5px;"><SaveOutlined :style="{fontSize: '24px'}"/></span><b>Angebot herunterladen</b></a-button>
    </div>
  </div>    
    <a-modal
      :title="'Konfiguration abbrechen?'"
      v-model:open="cancelConf"
      :closable="true"
      @cancel="cancelConf = false"
      @ok="exitConfig()"
      okText="Ok"
      destroyOnClose
      cancelText="Abbrechen"
      centered>
      Wollen Sie die Konfiguration wirklich abbrechen? Alle bisher angegeben Daten werden komplett zurück gesetzt!
    </a-modal>
    <a-spin :spinning="loading">
      <a-modal
      style="width: 400px;"
      v-model:open="showSaveConfig"
      :title="'Angebot speichern'"
      :closable="false"
      :maskClosable="false"
      cancelText="Abbrechen"
      @cancel="showSaveConfig=false"
      @ok="handleSaveConfigSubmit"
      destroyOnClose
      :confirmLoading="loading"
      centered>
      <a-form
        :ref="formRefSave"
        :model="formSave"
        :rules="rulesSave"
        :loading="loading"
        layout="vertical"
        @finish="handleSaveConfigSubmit">
        <!-- WP Implementation -->
        <a-form-item ref="configName" name="configName">
          <template #label>
            <tooltip-label :title="'Angebotsname'" :tooltip="'Geben Sie der abzuspeichernden Konfiguration einen eindeutigen Namen, dieser wird im Angebot angezeigt. Die Maximallänge betragt 60 Zeichen, erlaubt sind nur Buchstaben, Zahlen, ._ und Leerzeichen!'"/>
          </template>
          <a-input
            placeholder="Name des Angebots"
            id="saveConfigNameInput"
            v-model:value="formSave.configName"
          >
          </a-input>
        </a-form-item>
        <a-form-item ref="configNumber" name="configNumber">
          <template #label>
            <tooltip-label :title="'Angebotsnummer'" :tooltip="'Eine Angebotsnummer ist optional, ist aber auch im Angebot sichtbar. Die Maximallänge beträgt 60 Zeichen, erlaubt sind nur Buchstaben, Zahlen und ._'"/>
          </template>
          <a-input
            placeholder="Angebotsnummer"
            id="saveConfigNumberInput"
            v-model:value="formSave.configNumber"
          >
          </a-input>
        </a-form-item>
        <a-form-item ref="selectedSubAccountForSave" name="selectedSubAccountForSave">
          <template #label>
            <tooltip-label :title="'Customer auswählen'" :tooltip="'Bitte wählen Sie den Customer aus, für den Sie das konfigurierte Angebot speichern möchten'"/>
          </template>
          <a-select
            id="subAccountSelect"
            @change="handleSelectChange"
            placeholder="Customer auswählen"
            v-model:value="formSave.selectedSubAccountForSave"
          >
            <a-select-option v-for="account in accountData" :key="account.id" >
              {{ account.name}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
     </a-modal>
    </a-spin>
    
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { dialog, os } from "@/mixins/mixins.js";
import TooltipLabel from '@/components/label/TooltipLabel.vue'
import { message, notification, Popover } from 'ant-design-vue'
import api from '@/apis/apis'
import { ref, reactive, toRaw } from 'vue'
import { SaveOutlined, DownloadOutlined } from '@ant-design/icons-vue'

export default {
  name: "CostOverview",
  mixins: [dialog, os],
  components: {
    TooltipLabel,
    notification,
    SaveOutlined,
    DownloadOutlined,
  },
  data() {
    return {
      accountid: this.getCookie('account'),
      privacy: false,
      updateIndex: 0,
      updateSum: 0,
      total: 0,
      billData: [],
      email: '',
      activeKey: '1',
      windowWidth: window.innerHeight, 
      emailRegex: /^([.]{0,1}[a-zA-Z0-9_-])+@([.]{0,1}[a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,10}){1,2})$/,
      configNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.]*$/,
      configNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9_. ]*$/,
      cancelConf: false,
      partnerValue: ref('Standard'),
      configButton: false,
      showSaveConfig: false,
      loading: false,
      fromInput: false,
      accountData: [],
      houseOptions: ref([
        {
          partnerValue: null,
          text: "Standard",
        },
        {
          partnerValue: "Holz",
          text: "Reseller (Holz)",
        },
        {
          partnerValue: "Bronze",
          text: "Reseller (Bronze)",
        },
        {
          partnerValue: "Silber",
          text: "Reseller (Silber)",
        },
        {
          partnerValue: "Gold",
          text: "Reseller (Gold)",
        },
        {
          partnerValue: "Platin",
          text: "Reseller (Platin)",
        },
      ])
    };
  },
  computed: {
    ...mapGetters([
      'bill',
      'selectedServer',
      'serverList',
      'discount',
      'discountType',
      'isSidebarOpen',
      'partnerDiscount'
    ]),
    isRoot() {
      return this.$route.path.endsWith("/");
    },
    summary() {
      let sum = 0.0
      let originalSum = 0.0
      this.bill.forEach((el) => {
        for (let pos of el.bill) {
          sum += Number(pos.price)
          if (pos.originalPrice !== undefined) {
            originalSum += Number(pos.originalPrice)
          }
        }
      });

      return [sum.toFixed(2), originalSum.toFixed(2)];
    },
    vkPriceSummary() {
      if(!this.fromInput){
        let sum = 0.0
        this.bill.forEach((el) => {
          for (let pos of el.bill) {
            if(pos.originalVkPrice != undefined){
              sum += Number(pos.originalVkPrice)
            }
          }
        });
        return sum.toFixed(2)
      }
      else{
        return this.total.toFixed(2)
      }
      
    },

    costPerHour() {
      return ((this.summary[0]) / 730).toFixed(2);
    },
    isCloudOrIaas() {
      return (
        this.$store.getters['selectedServer'].type === "iaas" ||
        this.$store.getters['selectedServer'].type === "cloudServer" ||
        (this.$store.getters['serverList'].length > 0 &&
          (this.$store.getters['serverList'][0].type === "cloudServer" ||
          this.$store.getters['serverList'][0].type === "iaas"))
      );
    },
    houseSelect: {
      get() {
        return this.discountType;
      },
      set(value) {
        var selectedDiscount
        this.setDiscountType(value);
        switch (value) {
          case 'Holz': selectedDiscount = 20
            break
          case 'Bronze': selectedDiscount = 30
            break
          case 'Silber': selectedDiscount = 35
            break
          case 'Gold': selectedDiscount = 40
            break
          case 'Platin': selectedDiscount = 47
            break
          default: selectedDiscount = 0
        }
        this.$store.commit("setPartnerDiscount", selectedDiscount)
        if (this.serverList.length > 0) {
          for (var server of this.serverList) {
            var before = server.configuration.vCPU
            // this.$store.commit("setSelectedServer", server)
            server.configuration.vCPU = 1
            server.configuration.vCPU = before
            }
        }
      },
    },
    calculateDiscount() {
      let sum = this.summary[0];
      if (this.discount === 0) return 0;
      sum = sum * (this.discount / 100);
      return sum.toFixed(2);
    },
  },
  created() {
    this.initForm()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.configButton = false;
    this.listAccountsForReseller()
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    ...mapMutations(["setSidebarVisibility", "clearServer", "setDiscountType", "setImportedConfigName", "setImportedConfigNumber", "setCustomerId"]),
    onResize() {
      this.windowWidth = window.innerWidth
    },
    initForm () {
      this.formRef = ref()
      this.form = reactive({})
      this.rules = reactive({
        checkbox: [{ required: true, message: "Erforderlich" }],
        email: [{ required: true, message: "Erforderlich" }, { validator: this.validateEmail }],
      })
      this.formRefSave = ref()
      this.formSave = reactive({})
      this.rulesSave = reactive({
        configName: [{required: true, message: "Erforderlich"}, {validator: this.validateConfigName}],
        configNumber: [{required: false, message: "Eine gültige Angebotsnummer besteht aus Buchstaben, Zahlen ._ und hat maximal 60 Zeichen."}, {validator: this.validateConfigNumber}],
        selectedSubAccountForSave: [{required: true, message: "Für welchen Account möchten sie das Angebot speichern?"}]
      })
    },
    handleEnter(event, bill, resourceId) {
      this.total = 0
      this.fromInput = true

      var firstIp = false
      var specialCase = false
      for (var server of bill) {
        specialCase = false
        var resource = server.bill.filter(x => x.resourceId === resourceId)[0]
        if (resource !== undefined) {
          resource.vkSinglePrice = event
          var tempvkSinglePrice = resource.vkSinglePrice
          // logic to make sure that allways only one IP per OFFer is free
          if (resourceId == 3) {
            if(bill.length == 1) {
              if(resource.count == 1) {
              resource.originalVkPrice = 0
              specialCase = true
              }
              else{
              resource.originalVkPrice = tempvkSinglePrice * ((parseInt(resource.count)) - 1)
              specialCase = true
              }
            } else{
              if(resource.count == "1" && !firstIp) {
                firstIp = true
                specialCase = true
                resource.originalVkPrice = 0
              }
            }
          }
          // MSSQL set total to 0 if no Cpus are configured
          if (resourceId == 5 && resource.count == 0){
            tempvkSinglePrice = 0
          } 
          if(specialCase == false){
            resource.originalVkPrice = tempvkSinglePrice * parseInt(resource.count)
          }        
          this.$store.commit('setCustomVkPrices', {id:resourceId, customPrice: resource.vkSinglePrice})

          for(let i = 0; i<server.bill.length; i++) {
            if(server.bill[i].originalVkPrice != undefined){
              this.total += Number(server.bill[i].originalVkPrice)
            }  
          }
    
          this.updateSum += 1
          this.updateIndex += 1
          this.fromInput = false
        }
      }
    },
    saveConfig () {
      this.formSave.configName = this.$store.getters.getImportedConfigName
      this.formSave.configNumber = this.$store.getters.getImportedConfigNumber
      this.formSave.selectedSubAccountForSave = this.$store.getters.getCustomerId

      this.showSaveConfig = true
    },
    getOffer () {
        var params = new FormData()
        params.append('object', JSON.stringify(this.$store.getters.bill))
        params.append('account', this.accountid)
        params.append('customerId', this.$store.getters.getCustomerId)
        params.append('offerName', this.$store.getters.getImportedConfigName)
        params.append('offerNumber', this.$store.getters.getImportedConfigNumber)
        params.append('customerName', this.$store.getters.getCustomerName)
        api.getPdf(params).then(response => {
        let filename = response.headers['content-disposition'].split("filename=")[1]
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
          this.$notification.success({
            message: 'Angebot wurde erstellt!',
          })
        }).catch(error => {
          console.log(error)
        })
    },
    async updateConfig (customer, configname, configNumber) {
      var updateParams = new FormData()
      updateParams.append('record', this.$store.getters.getConfigImportId)
      updateParams.append('object', JSON.stringify(this.$store.getters.bill))
      updateParams.append('importObject', JSON.stringify(this.$store.getters.serverList))
      updateParams.append('customerUuid', customer)
      updateParams.append('configName', configname)
      updateParams.append('configNumber', configNumber)
      await api.updateConfigurator(updateParams).then(response =>{
        this.loading = false
        this.configButton = true
        this.$notification.success({
          message: 'Erfolg!',
          description: 'Die Konfiguration wurde erfolgreich bearbeitet!',
          duration: 5
        })
      }).catch(error => {
        this.$notification.error({
          message: 'Es ist ein Fehler aufgetreten!',
          description: 'Die Konfiguration konnte nicht erfolgreich gespeichert werden. Bitte versuchen Sie es erneut!',
          duration: 0
        })
        console.log("Error saving config",error)
        this.loading = false
      })
    },
    handleSaveConfigSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      if (this.$store.getters.getConfigImportId) {
        this.formRefSave.value.validate().then(async () => {
          var customer = this.formSave.selectedSubAccountForSave
          var configname = this.formSave.configName
          var configNumber = this.formSave.configNumber
          this.updateConfig(customer, configname, configNumber)
          this.showSaveConfig = false
          this.setCustomerId(customer)
          this.setImportedConfigName(configname)
          this.setImportedConfigNumber(configNumber)
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
      } else {
        this.formRefSave.value.validate().then(async () => {
          const values = toRaw(this.formSave)
          this.setCustomerId(values.selectedSubAccountForSave)
          this.setImportedConfigName(values.configName)
          this.setImportedConfigNumber(values.configNumber)
          var params = new FormData()
          params.append('object', JSON.stringify(this.$store.getters.bill))
          params.append('importObject', JSON.stringify(this.$store.getters.serverList))
          params.append('resellerUuid', this.accountid)
          params.append('customerUuid', values.selectedSubAccountForSave)
          params.append('configName', values.configName)
          params.append('configNumber', values.configNumber)
          await api.saveConfigurator(params).then(response =>{
            this.$store.commit('setConfigImportId', response.data)
            this.loading = false
            this.showSaveConfig = false
            this.configButton = true
            this.$notification.success({
              message: 'Erfolg!',
              description: 'Die Konfiguration "' + values.configName + '" wurde erfolgreich gespeichert!',
              duration: 5
            })
          }).catch(error => {
            this.$notification.error({
              message: 'Es ist ein Fehler aufgetreten!',
              description: 'Die Konfiguration "' + values.configName + '" konnte nicht erfolgreich gespeichert werden. Bitte versuchen Sie es erneut!',
              duration: 0
            })
            console.log("Error saving config",error)
            this.loading = false
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
      }
    },
    async listAccountsForReseller(){
      this.accountLoading = true
      this.accountid = this.getCookie('account')
      await api.listAccounts(this.accountid).then(response =>{
        this.accountData = response.data
        this.selectedSubAccountForSave = response.data[0].id
        this.accountLoading = false
      }).catch(error => {
        console.log("error account",error)
        this.accountLoading = false
      })
    },
    handleSelectChange(value) {
      var subAccount = this.accountData.filter(account => account.id === value)[0]
      if (subAccount !== undefined) {
        this.$store.commit("setCustomerName", subAccount.name)
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    /* submit(e) {
      e.preventDefault()
      if (this.$store.getters.bill.length === 0) {
        return
      }
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)
        let params = new FormData()
        params.append('object', JSON.stringify(this.$store.getters.bill))
        params.append('account', this.getCookie('account'))
        api.getPdf(params).then(response => {
          this.$notification.success({
            message: 'Angebot versendet!',
            description: 'Das von Ihnen konfigurierte Angebot wurde erfolgreich an ' + values.email + ' versendet.'
          })
          // console.log(response.data)
          // let filename = response.headers['content-disposition'].split("filename=")[1]
          // let url = window.URL.createObjectURL(new Blob([response.data]));
          // let link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', filename); //or any other extension
          // document.body.appendChild(link);
          // link.click();
        }).catch(error => {
          console.log(error)
        })
      }).catch(error => {
          this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    }, */
    validateEmail (rule, value) {
      const errorMessage = 'Bitte geben Sie eine valide E-Mail Adresse an!'
      if (this.emailRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateConfigName (rule, value) {
      const errorMessage = 'Eine gültiger Konfigurationsname besteht aus Buchstaben, Zahlen ._ und hat maximal 60 Zeichen.'
      if(value === undefined || value.length <= 60) {
        if (this.configNameRegex.test(value)) {
          return Promise.resolve()
        }
      }
      return Promise.reject(errorMessage)
    },
    validateConfigNumber (rule, value) {
      const errorMessage = 'Eine gültige Angebotsnummer besteht aus Buchstaben, Zahlen ._ und hat maximal 60 Zeichen.'
      var regexerror = false

      if (value !== undefined && value !== null) {
        if(value.length >= 60) {
          regexerror = true
        }

        if (!this.configNumberRegex.test(value)) {
          regexerror = true
        }

        if (regexerror == true) {
          return Promise.reject(errorMessage)
        }
      }
      return Promise.resolve()
    },
    exitConfig() {
      this.setSidebarVisibility(false);
      this.formSave.configName = undefined
      this.formSave.configNumber = undefined
      this.formSave.selectedSubAccountForSave = undefined
      this.clearServer();
      this.$router.push("/prices")
      this.cancelConf = false
      this.configButton = false
    },
    getDifferenz(original, discount) {
      var diff = original - discount
      return diff.toFixed(2)
    },
  },
};
</script>

<style lang="scss">
.hoverLabel {
  width: 65%;
}
.hoverLabel:hover {
  font-weight: bold;
} 
.text-white {
  color: white;
}
.standardPartner {
  background-color: #3d7caf;
}
.woodPartner {
  background-color: #9a7b4f;
}
.bronzePartner {
  background-color: #cd7f32;
}
.silverPartner {
  background-color: #afafaf;
}
.goldPartner {
  background-color: #edc006;
}
.platinumPartner {
  background-color: #a0b2c6;
}

.exit-button {
  color: #fff !important;
  font-weight: bold;
  margin-left: 10px;
}
.kdz-costs {
  height: 550px;
  width: 290px;
  overflow: auto;
  transition: 0.8s all ease-in-out;
}
.kdz-cost-border {
  border-bottom: 1px dotted #fff;
}

.kdz-sidebar-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid white;
  font-size: 25px;
  color: white;
}

.kdz-cancel {
  display: flex;
  align-items: center;
}

.kdz-cost-list {
  max-height: 600px;
  font-size: 16px;
  overflow-y: auto;
  text-align: left;
  padding: 0px 3px 0px 3px;
  color: white;
}
.kdz-cost-sum {
  color: white;
}
.kdz-last-element {
  border-bottom: 1px solid #fff;
  padding-bottom: 0.5rem;
}

.kdz-cost-sum {
  padding: 0.5rem 1rem;
  border-top: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .big {
    font-size: 25px;
    font-weight: bold;
    text-decoration: underline double;
  }
  .vkbig {
    font-size: 18px;
    font-weight: bold;
  }
}
.costoverview.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab{
  border-radius: 0px;
  width: 145px;
  font-size: large;
  color: black;
  background: #EEEEEE;
}

.costoverview.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active{
  background: white
}

@media (min-width: 769px) {
  .kdz-costs {
    min-height: calc(500px + 2.5rem);
    height: 100%;
  }
  .kdz-sidebar-head {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .kdz-sidebar-head {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .kdz-costs {
    height: 100%;
    width: 100%;
    padding: 12px 12px 12px 12px;
    display: flex;
    flex-direction: column;
  }
  .kdz-sidebar-head {
    font-size: 20px;
  }

  .kdz-cost-sum {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
}
</style>
