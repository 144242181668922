<template>
  <div>
    <div v-for="(server, index) in cloudServer" :key="index">
        <CloudServer :serverId="server.id" :isLast="index === cloudServer.length - 1" v-on:addServer="createNewServer"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CloudServer from "@/components/server/CloudServer.vue";

export default {
  name: "CloudServerView",
  components: {
    CloudServer
  },
  mounted () {
    this.$store.commit("setSidebarVisibility", false);
    // this.$store.commit("clearServer");
  },
  async beforeRouteLeave (to, from) {
    if (to.path === '/iaas' || to.path === '/collaboration' || to.path === '/cybercloudendpoint' || to.path === '/container') {
      if (this.$store.getters.isSidebarOpen) {
        const confirm = window.confirm('Bitte beenden Sie die momentane Konfiguration! Ansonsten gehen nicht gespeicherte Daten verloren.')
        if (!confirm) {
          return false
        }
      }
      this.$store.commit("resetServer");
      this.$store.commit("setSidebarVisibility", false);
    }
  },
  computed: {
    ...mapGetters(["serversByType", "send"]),
    cloudServer() {
      let server = this.serversByType("cloudServer");
      // if (server.length === 0) {
      //   this.createNewServer();
      // }
      return server;
    },
    isCloudServerEmpty() {
      let server = this.serversByType("cloudServer");
      if (Object.keys(server).length === 0) {
        return true;
      }
      return (
        Object.keys(this.serversByType("cloudServer")[0].configuration)
          .length === 0
      );
    },
  },
  methods: {
    ...mapMutations([
      "setSelectedServer",
      "addServer",
      "setSidebarVisibility",
      "setFirewall",
    ]),
    openModal () {
      this.changeRoute = true
    },
    createNewServer() {
      this.setSelectedServer({
        id: Date.now(),
        name: "Cloud Server",
        type: "cloudServer",
        configuration: {},
      });
      this.addServer();
    },
    createServerIfEmpty() {
      if (this.cloudServer.length === 0) {
        this.createNewServer();
      }
    },
  },
  beforeUpdate() {
    this.createServerIfEmpty();
  },
  mounted() {
    this.createServerIfEmpty();
  },
};
</script>

<style lang="scss" scoped>
</style>