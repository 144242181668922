<template>
  <div class="panel-server">
    <div>
      <span>{{ server.name}} </span>
      <div>
        <div class="clickable"  @click="editServer" style="display: flex; align-items: center; margin-top: 5px; width: 95px;">
          <img style="margin-right: 2px;" width="20" src='../../assets/button_square.svg'/>
          Bearbeiten
        </div>
      </div>
      <div >
        <div class="clickable" @click="deleteServerModal = true" style="display: flex; align-items: center; width: 75px;">
          <img width="20" style="margin-right: 2px;" src='../../assets/trash.svg'/>
          Löschen
        </div>
      </div>
    </div>
    <a-modal
        :title="'Server löschen?'"
        v-model:open="deleteServerModal"
        :closable="true"
        @cancel="deleteServerModal = false"
        @ok="deleteConfig()"
        okText="Ok"
        cancelText="Abbrechen"
        centered>
        Wollen Sie den Server wirklich löschen?
      </a-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { dialog, imageUrl } from "@/mixins/mixins.js";

export default {
  name: "BackUpServer",
  props: {
    server: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleteServerModal: false,
    };
  },
  mixins: [dialog, imageUrl],
  computed: {
    capacity: {
      get() {
        return this.server.configuration.capacity;
      },
    },
  },
  methods: {
    ...mapMutations([
      "deleteServer",
      "setSelectedServer",
      "setSidebarVisibility",
    ]),
    deleteConfig() {
      this.deleteServer(this.server.id);
      this.setSidebarVisibility(false);
      this.deleteServerModal = false
    },
    editServer() {
      this.setSelectedServer(this.server);
      this.setSidebarVisibility(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.panel-server {
  background: url("../../assets/panel.svg") no-repeat;
  width: 260px;
  padding: 10px;
  z-index: 5;
  position: relative;
  display: flex;
}

@media (min-width: 1200px) {
  .panel-server {
    width: 180px;
    padding: 10px;
    height: 100px;
  }
}
@media (max-width: 1199px) {
  .panel-server {
    width: 170px;
    padding: 10px;
    height: 100px;
  }
}

@media (max-width: 930px) {
  
}

@media (max-width: 790px) {

}
</style>