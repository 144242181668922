<template>
    <a-modal
      v-if="openModal"
      style="top: 20px;"
      centered
      :title="resourceToDelete + ' ' + resourceName + ' entfernen'"
      :closable="false"
      :loading="loading"
      :maskClosable="false"
      v-model:open="openModal"
    >
      <div style="display: flex; justify-content: center;">
        <DeleteOutlined :style="{fontSize: '40px', color: 'red'}" />
        <span style="margin-left: 20px;"><span> {{ modalText() }}</span><span style="font-weight: bold;">{{ resourceName }}</span><span>{{ ' wirklich löschen möchten?' }}</span></span>
      </div>
      <template #footer style="text-align: right;">
        <a-button id="deleteUserAccountCancel" type="default" @click="closeDeleteModal()">Abbrechen</a-button>
        <a-button id="deleteUserAccountOk" type="primary" :loading="loading" @click="deleteResource()">Löschen!</a-button>
      </template>
    </a-modal>
</template>

<script>

import { DeleteOutlined } from '@ant-design/icons-vue'
import api from '../../apis/apis'
import { notification } from 'ant-design-vue'

export default {

    name: 'DeleteAccountAndUsersForm',
    components: {
        DeleteOutlined,
        notification
    },
    props: {
      resourceToDelete: {
        type: String,
        required: true
      },
      deletionModal: {
        type: Boolean,
        required: true
      },
      resourceName: {
        type: String,
        required: true
      },
      resourceUuid: {
        type: String,
        required: true
      },
      userDomainId: {
        type: String,
        required: false
      },
      accountName: {
        type: String,
        required: false
      },
      configuratorId: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        openModal: false,
        loading: false,
        openModal: false
      }
    },
    watch: {
      deletionModal: function(newVal, oldVal) {
        this.openModal = newVal
      }
    },
    methods: {
      async deleteResource () {
        this.loading = true
        switch (this.resourceToDelete) {
          case 'Benutzerkonto':
            await this.deleteAccount(this.resourceUuid)
            break
          case 'Angebot':
            await this.deleteConfigurator(this.configuratorId)
            break
          case 'Benutzer':
            await this.deleteUser(this.resourceUuid)
            break
          default:
            console.log("Es ist keine Ressource zum löschen vorhanden")
        }
      },
      async deleteAccount (uuid) {
        await api.deleteAccount(uuid).then(response =>{
          this.$emit('reload-accounts')
          this.$emit('close-modal')
          this.loading = false
          this.$notification.success({
            message: 'Erfolg',
            description:
              'Das Benutzerkonto wurde erfolgreich gelöscht.',
            duration: 4
          })
        }).catch(error => {
          this.$notification.error({
              message: 'Fehler beim Löschen des Benutzerkontos',
              description:
                'Das Benutzerkonto konnte nicht gelöscht werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
              duration: 0
          })
          this.$emit('close-modal')
          this.loading = false
          console.log("error deleting Account id: " ,error)
        })
      },
      async deleteUser (uuid) {
        this.loading = true
        await api.deleteUser(uuid).then(response =>{
          this.$notification.success({
            message: 'Erfolg',
            description:
              'Das Benutzerkonto wurde erfolgreich gelöscht.',
            duration: 4
          })
          this.loading = false
          this.$emit('reload-user',this.accountName, this.userDomainId)
          this.$emit('close-modal')
        }).catch(error => {
          this.$notification.error({
            message: 'Fehler beim Löschen des Benutzers',
            description:
              'Der Benutzer konnte nicht gelöscht werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
            duration: 0
          })
          this.loading = false
          this.$emit('close-modal')
          console.log("error deleting User: " ,error)
        })
      },
      async deleteConfigurator (id) {
        this.loading = true
        var params = new FormData()
        params.append('id', id)
        await api.deleteConfigurator(params).then(response =>{
          this.$notification.success({
            message: 'Erfolg',
            description:
              'Das Angebot wurde gelöscht.',
            duration: 6
          })
          this.loading = false
          this.$emit('loadConfigs', 2)
          this.$emit('close-modal')
        }).catch(error => {
          this.$notification.error({
            message: 'Fehler beim Löschen des Angebotes',
            description:
              'Das Angebot konnte nicht gelöscht werden, bitte versuchen Sie es erneut. (' + error.response.data.split('):')[1] + ')',
            duration: 0
          })
          this.loading = false
          this.$emit('close-modal')
          console.log("error deleting User: " ,error)
        })
      },
      closeDeleteModal () {
        this.openModal = false
        this.loading = false
        this.$emit('close-modal')
      },
      modalText () {
        var text
        switch(this.resourceToDelete) {
          case 'Benutzerkonto':
            text = 'Sind Sie sich sicher, dass Sie das Benutzerkonto '
            break;
          case 'Benutzer':
            text = 'Sind Sie sich sicher, dass Sie den Benutzer '
            break;
          case 'Angebot':
            text = 'Sind Sie sich sicher, dass Sie das Angebot '
            break;
          default:
            console.log('Ressource ist nicht vorhanden')
        }
        return text
      }
    }
}
</script>

<style>

</style>
