<template>
  <div>
    <div v-if="serverType === 'cloudServer' || serverType === 'iaas'">
      <strong>Betriebssystem</strong>
      <div>
        <template v-for="(option, i) of priceList" :key="i">
          <div :class="{ 'os-field': option[0] === os }" v-if="option[1].isOS">
            <a-radio-group
              :key="i"
              class="os-buttons"
              v-model:value="os"
              @change="($event) => updateSelectedRadio($event.target.value)">
              <a-radio-button
                :id="option[0]"
                :value="option[0]"
                >
                <div style="display: flex; justify-content: center; align-items: center;">
                  <img :src="getImgUrl(option[1].image)" width='24'/>&nbsp;
                  <span>{{option[1].label }}</span>&nbsp;
                  <a-tooltip id="hint" v-if="option[0] === 'Linux'">
                <template #title>{{ 'Es sind unterschiedliche Linux-Versionen möglich.' }}</template>
                <ExclamationCircleOutlined />
              </a-tooltip>
                </div>
            </a-radio-button>
            </a-radio-group>
            <div v-if="os === option[0]">
              <ConfigFormField
                v-for="(field, j) of filter(option[0])"
                :key="'option' + j"
                :options="field"
                :serverType="serverType"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="serverType === 'wpCloud'" style="display: flex; flex-direction: column;">
      <br>
      <strong>Extra-Speicher(optional):</strong>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="'Extra-SSD Storage in GB'"></span>&nbsp;
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="SSD"
          :min="0"
          :max="9899"
        ></a-input>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; padding: 2px;">
        <span style="text-align: left; width: 212px;">
          <span v-html="'Extra-HDD Storage in GB'"></span>&nbsp;
        </span>
        <a-input
          type="number"
          :class="['inputNumberField']"
          v-model:value="HDD"
          :min="0"
          :max="9999"
        ></a-input>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ConfigFormField from "./ConfigFormField.vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'


export default {
  name: "ConfigOsList",
  props:{
    serverType: {
      type: String,
      required: true,
    }
  },
  components: {
    ConfigFormField,
    ExclamationCircleOutlined
  },
  data() {
    return {
      selectedRadio: '',
    };
  },
  computed: {
    ...mapGetters(["selectedServer"]),
    priceList() {
      return this.$store.getters.getPriceList
    },
    os: {
      get() {
        return this.selectedServer.configuration.os;
      },
      set(value) {
        let server = Object.assign({}, this.selectedServer);
        if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
          server.configuration[server.configuration.os] = 0
        }
        server.configuration.os = value;
        if (Object.keys(this.selectedServer.configuration).length !== 0)
          this.setSelectedConfiguration(server.configuration);
        if (((server.configuration.os).toLowerCase()).includes("windows") === true) {
          server.configuration[server.configuration.os] = 1
        }
      },
    },
    SSD: {
      get() {
        return (this.selectedServer.configuration["SSD"]-100)
      },
      set(value) {
        if (value < 0 || value == "") {
          this.updateConfig("SSD", 100);
        } else if( value > 9899){
          this.updateConfig("SSD", 9999);
        } else {
          this.updateConfig("SSD", parseInt(value)+100);
        }
      }
    },
    HDD: {
      get() {
        return this.selectedServer.configuration["HDD"]
      },
      set(value) {
        if (value > 9999) {
          this.updateConfig("HDD", 9999);
        } else {
          this.updateConfig("HDD", value);
        }
      }
    },
  },
  methods: {
    ...mapMutations(["setSelectedConfiguration"]),
    getImgUrl(pic) {
      return require('../../assets/'+pic)
    }, 
    updateSelectedRadio (currentOS) {
      let server = this.selectedServer
      server.configuration[this.selectedRadio] = ""
      this.selectedRadio = currentOS

      if (((this.selectedRadio).toLowerCase()).includes("windows") === true) {
        if (server.configuration["vCPU"] > 0) {
          server.configuration[this.selectedRadio] = server.configuration["vCPU"]
        }
      }
      
      for (var item of this.priceList) {
        if ( item[1].isOS !== true && item[1].isResource !== true )
        server.configuration[item[0]] = 0
      }
    },
    filter(osName) {
      const original = this.$store.getters.getPriceList
      var clone = new Map(original)
      for (var [key, value] of clone.entries()) {
        if (value.isOS || value.isResource) {
          clone.delete(key)
        }
        if (value.label.includes('TSPlus Trial')){
          clone.delete(key)
        }
        if (osName === 'Linux') {
          if (value.label === 'LibreOffice Lizenz' || value.label.includes('GPU') || value.label.includes('Cyber Cloud'))
            continue
        }
        if (osName === 'Windows_11' || osName === 'Windows_10') {
          if (value.label.includes('GPU') || value.label.includes('LibreOffice Lizenz') || value.label.includes('MS Office') || value.label.includes('TSPlus') || value.label.includes('MSSQL') ||value.label.includes('Acronis') || value.label.includes('Cyber Cloud'))
            continue
        }
        if (!osName.includes('Server')) {
          clone.delete(key)
        }
      }
      return clone
    },
    updateConfig(attribute, value) {
      let server = this.selectedServer;
      server.configuration[attribute] = value;
      this.setSelectedConfiguration(server.configuration);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputNumberField {
  width: 60px;
}
/* .ant-radio-group {
  width: 100%;
  border-radius: 50px;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
} */
.ant-radio-button-wrapper{
  width: 100%;
  border-radius: 50px;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
}
.ant-radio-button-wrapper:hover{
  background-color: rgb(207, 205, 205);
}
.ant-radio-button {
  width: 100%;
  background-color: rgb(233, 231, 231);
  margin-bottom: 5px;
}
.clickable {
  cursor: pointer;

  &:hover {
    color: #d02c2a;
  }
}
.os-buttons {
  width: 100%;
  .btn {
    border-radius: 50rem;
    width: 100%;
    margin: 0.25rem 0 !important;
    text-align: left;
    &.active {
      z-index: 0;
    }
    &,
    & > div {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.os-field {
  background-color: #fff;
  color: #000;
  margin-bottom: 10px;
}
</style>
